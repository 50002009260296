import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
// import NumberFormat from "react-number-format";
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Calendar } from 'primereact/calendar';
import { Button as ButtonP } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputMask } from 'primereact/inputmask';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { locale, addLocale } from 'primereact/api';

// funcoes proprias
import Api from '../Api';
import { AuthContext } from '../contextos/Auth';
import IfComponent from '../componentes/IfComponent';
import ClientesLoc from '../componentes/ClientesLoc';
import EquipamentosForm from '../componentes/EquipamentosForm';
import EquipamentosLoc from '../componentes/EquipamentosLoc';
import VendedoresLoc from '../componentes/VendedoresLoc';
import ClientesForm from '../componentes/ClientesForm';
import Sentinela from '../componentes/Sentinela';

function Servicos() {
    const { logout } = useContext(AuthContext);
    const navigate = useNavigate();
    const [ listagem, setListagem ] = useState([]);
    const [ carregando, setCarregando ] = useState(true);
    const [ pagina, setPagina ] = useState(0);
    const [ final, setFinal ] = useState(false);
    const [ servico, setServico ] = useState({'SER_CLIENTE': '', 'CLI_NOME':'', 'SER_DTENTRADA': moment().format('DD/MM/YYYY'), SER_KMATUAL: ''});
    const [ filtros, setFiltros ] = useState({
        dataInicial: '',
        dataFinal: '',
        andamento: 'TODOS',
        status: 'TODOS',
        viaInternet: 'TODOS',
        pesquisa: ''
    });
    const selectAndamento = [
        {value: "TODOS", label: "TODOS" },
        {value: "AGUARDANDO APROVACAO", label: "AGUARDANDO APROVACAO" },
        {value: "AGUARDANDO INICIO", label: "AGUARDANDO INICIO" },
        {value: "AGUARDANDO RETIRADA", label: "AGUARDANDO RETIRADA" },
        {value: "EM TRABALHO", label: "EM TRABALHO" },
        {value: "FINALIZADA", label: "FINALIZADA" },
        {value: "PAUSADA", label: "PAUSADA" },
    ];
    const selectStatus = [
        {value: "TODOS", label: "TODOS" },
        {value: "A", label: "ABERTOS" },
        {value: "B", label: "BAIXADOS" },
        {value: "F", label: "FINALIZADOS" },
    ];

    const [ showModal, setShowModal ] = useState(false); //para novo pedido.
    const [ showModalFiltros, setShowModalFiltros ] = useState(false); //para os filtros.
    const [ showClientesLoc, setShowClientesLoc ] = useState(false);
    const [ showEquipamentosLoc, setShowEquipamentosLoc ] = useState(false);
    const [ showVendedoresLoc, setShowVendedoresLoc ] = useState(false);
    const [ showClientesForm, setShowClientesForm ] = useState(false);
    const [ showEquipamentosForm, setShowEquipamentosForm ] = useState(false);

    function listarServicos(nPagina,bFinal,bLimpar) {
        if (!bFinal) {
            setCarregando(true);    
            const novaPagina=nPagina+1;
            //setPagina((paginaInsideState) => paginaInsideState+1);
                        
            var formData = new FormData();
            formData.set('op', 'listar');
            formData.set('pagina', novaPagina);
            formData.set('pesquisa', filtros.pesquisa);
            formData.set('datainicial', new Date(filtros.dataInicial).toLocaleDateString('pt-BR')); //a string em filtros está assim: 2024-12-10T03:00:00.000Z e desta forma pegamos apenas a data neste formado: 10/12/2024
            formData.set('datafinal', new Date(filtros.dataFinal).toLocaleDateString());
            formData.set('status', filtros.status);
            formData.set('andamento', filtros.andamento);
                    
            Api.post('servicos.php',formData).then((response) => {
                if (response.data.erro==='N') {
                    const novaListagem = [];
                    if (response.data.contador > 0) {
                        if (!bLimpar){
                            //const novaListagem = [...listagem] //novalistagem recebe listagem atual.
                            novaListagem.push(...listagem); //novalistagem recebe listagem atual.
                            setListagem([]);
                        }
                        novaListagem.push(...response.data.msg); //novalistagem recebe mais a nova listagem.
                    } else {
                        //se ja tem uma listagem, continua com ela
                        //se a pesquisa retornou 30 registros, ao dar scroll para a pagina 2 que retorna com 0 registros, continua com a listagem atual.
                        if (novaPagina > 1) { 
                            novaListagem.push(...listagem); 
                        }
                    }

                    //verifica se pode conter mais registros. 
                    //se retornou com 20 registros, pode ser que ainda tenha mais.
                    //se retornou com menos de 30 registros, é pq chegou no final da lista.
                    if (response.data.contador < 30) {
                        setFinal(true);
                    }

                    setListagem(novaListagem);
                    setCarregando(false);
                    setPagina(novaPagina);
                } else {
                    response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
                }
            }).catch(({response}) => {
                setCarregando(false);
            });
        }
    }

    function carregarMais() {
        listarServicos(pagina,final,false);
    }

    useEffect(() => {
        locale('pt');
        addLocale('pt', {
            "startsWith":"Começa com",
            "contains":"Contém",
            "notContains":"Não contém",
            "endsWith":"Termina com",
            "equals":"Igual",
            "notEquals":"Diferente",
            "noFilter":"Sem filtro",
            "filter": "Filtro",
            "lt":"Menor que",
            "lte":"Menor que ou igual a",
            "gt":"Maior que",
            "gte":"Maior que ou igual a",
            "dateIs":"Data é",
            "dateIsNot":"Data não é",
            "dateBefore":"Date é anterior",
            "dateAfter":"Data é posterior",
            "custom":"Customizado",
            "clear":"Limpar",
            "close":"Fechar",
            "apply":"Aplicar",
            "matchAll":"Match All",
            "matchAny":"Match Any",
            "addRule":"Adicionar Regra",
            "removeRule":"Remover Regra",    
            "accept":"Sim",
            "reject":"Não",
            "choose":"Escolha",
            "upload":"Upload",
            "cancel":"Cancelar",
            "completed": "Concluído",
            "pending": "Pendente",
            "fileSizeTypes": ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
            "dayNames": ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
            "dayNamesShort": ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
            "dayNamesMin": ["D", "S", "T", "Q", "Q", "S", "S"],
            "monthNames": ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
            "monthNamesShort": ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
            "chooseYear": "Escolha Ano",
            "chooseMonth": "Escolha Mês",
            "chooseDate": "Escolha Data",
            "prevDecade": "Década Anterior",
            "nextDecade": "Década Seguinte",
            "prevYear": "Ano Anterior",
            "nextYear": "Ano Seguinte",
            "prevMonth": "Mês Anterior",
            "nextMonth": "Mês Seguinte",
            "prevHour": "Hora Anterior",
            "nextHour": "Hora Seguinte",
            "prevMinute": "Minuto Anterior",
            "nextMinute": "Minuto Seguinte",
            "prevSecond": "Segundo Anterior",
            "nextSecond": "Segundo Seguinte",
            "am": "am",
            "pm": "pm",
            "today": "Hoje",
            "now": "Agora",
            "weekHeader": "Sem",
            "firstDayOfWeek": 0,
            "showMonthAfterYear": false,
            "dateFormat": "dd/mm/yy",
            "weak": "Fraco",
            "medium": "Médio",
            "strong": "Forte",
            "passwordPrompt": "Digite uma senha",
            "emptyFilterMessage":"Nenhum resultado encontrado",
            "searchMessage": "{0} resultados disponíveis",
            "selectionMessage": "{0} itens selecionados",
            "emptySelectionMessage": "Nenhum item selecionado",
            "emptySearchMessage": "Nenhum resultado encontrado",
            "emptyMessage":"Nenhuma opção disponível",
            "aria": {
                "trueLabel": "Verdadeiro",
                "falseLabel": "Falso",
                "nullLabel": "Não selecionado",
                "star": "1 estrela",
                "stars": "{star} estrelas",
                "selectAll": "Todos itens selecionados",
                "unselectAll": "Nenhum item selecionado",
                "close": "Fechar",
                "previous": "Anterior",
                "next": "Seguinte",
                "navigation": "Navegação",
                "scrollTop": "Rolar para Topo",
                "moveTop": "Mover para Topo",
                "moveUp": "Mover para Cima",
                "moveDown": "Mover para Baixo",
                "moveBottom": "Mover para Final",
                "moveToTarget": "Mover para Alvo",
                "moveToSource": "Mover para Fonte",
                "moveAllToTarget": "Mover Todos para Alvo",
                "moveAllToSource": "Mover Todos para Fonte",
                "pageLabel": "Página {page}",
                "firstPageLabel": "Primeira Página",
                "lastPageLabel": "Última Página",
                "nextPageLabel": "Página Seguinte",
                "previousPageLabel": "Página Anterior",
                "rowsPerPageLabel": "Linhas por página",
                "jumpToPageDropdownLabel": "Pular para Menu da Página",
                "jumpToPageInputLabel": "Pular para Campo da Página",
                "selectRow": "Linha Selecionada",
                "unselectRow": "Linha Não Selecionada",
                "expandRow": "Linha Expandida",
                "collapseRow": "Linha Recolhida",
                "showFilterMenu": "Mostrar Menu de Filtro",
                "hideFilterMenu": "Esconder Menu de Filtro",
                "filterOperator": "Operador de Filtro",
                "filterConstraint": "Restrição de Filtro",
                "editRow": "Editar Linha",
                "saveEdit": "Salvar Editar",
                "cancelEdit": "Cancelar Editar",
                "listView": "Exibição em Lista",
                "gridView": "Exibição em Grade",
                "slide": "Deslizar",
                "slideNumber": "{slideNumber}",
                "zoomImage": "Ampliar Imagem",
                "zoomIn": "Mais Zoom",
                "zoomOut": "Menos Zoom",
                "rotateRight": "Girar à Direita",
                "rotateLeft": "Girar à Esquerda"
            }
        });
        carregarFiltros();
        listarServicos(0,false,true);      
        // eslint-disable-next-line
    },[]);

    function Pesquisar() {
        setPagina(0);
        setFinal(false);
        setListagem([]);
        listarServicos(0,false,true);
        setShowModalFiltros(false);

        //salva a pesquisa no local storage.
        localStorage.setItem('filtros_servicos', JSON.stringify(filtros));
    };
    
    async function btnSalvar() {
        const formData = new FormData();
        formData.set('op','inserirservico');
        formData.set('SER_ACESSORIOS',servico.SER_ACESSORIOS || '');
        formData.set('SER_CLIENTE',servico.SER_CLIENTE || '');
        formData.set('SER_DEFEITO',servico.SER_DEFEITO || '');
        formData.set('SER_DTPREVISAO',servico.SER_DTPREVISAO || '');
        formData.set('SER_EQUIPAMENTO',servico.SER_EQUIPAMENTO || '');
        formData.set('SER_KMATUAL',servico.SER_KMATUAL || '');
        formData.set('SER_TECNICO',servico.SER_TECNICO || '');
        const response = await Api.post('servicos.php',formData);
        if (response.data.erro==='N') {
            Swal.fire({title: 'Yessss!', text: 'Ordem de serviço gerada com sucesso!', icon: 'success', showConfirmButton: false, timer: 1500});
            setShowModal(false);
            navigate('/servico/'+response.data.msg.SER_CODIGO);
        } 
        if (response.data.erro==='S') {
            response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
        }
    }    

    function setCampo(e,objeto,upper) {
        const { name, value } = e.target;
        let valor='';
        if (upper) {
            valor = value?value.toUpperCase():'';
        } else {
            valor = value;
        }
        if (objeto==="servico") {
            setServico({...servico, [name]: valor});
        }
        if (objeto==="filtros") {
            setFiltros({...filtros, [name]: valor});
        }
    }

    function localizarKeyDown(e) {
        if (e.keyCode === 13) {
            Pesquisar();
        }
    } 

    function carregarFiltros() {
        const hoje = new Date();
        let dataInicial = new Date(hoje.getFullYear(), hoje.getMonth(), 1);
        let dataFinal = new Date(hoje.getFullYear(), hoje.getMonth() +1, 0);
        let andamento = 'TODOS';
        let status = 'TODOS';
        let pesquisa = '';
        const filtros_servicos = JSON.parse(localStorage.getItem('filtros_servicos'));
        if (filtros_servicos) {
            dataInicial = new Date(filtros_servicos.dataInicial);
            dataFinal = new Date(filtros_servicos.dataFinal);
            status = filtros_servicos.status;
            pesquisa = filtros_servicos.pesquisa;
        } else {
            localStorage.setItem('filtros_servicos',JSON.stringify({dataInicial, dataFinal, andamento, status, pesquisa}));
        }
        setFiltros({ dataInicial, dataFinal, andamento, status, pesquisa });
    }

    function resetarFiltros() {
        const hoje = new Date();
        const dataInicial = new Date(hoje.getFullYear(), hoje.getMonth(), 1);
        const dataFinal = new Date(hoje.getFullYear(), hoje.getMonth() +1, 0);
        const status = 'TODOS';
        const pesquisa = '';
        setFiltros({ dataInicial, dataFinal, status, pesquisa });
        localStorage.setItem('filtros_servicos',JSON.stringify({ dataInicial, dataFinal, status, pesquisa }));
    }

    //funcoes do modal de clientes.
    function clientesLocClose(event, data) {
        setServico({...servico, 'SER_CLIENTE': data.CLI_CODIGO, 'CLI_NOME': data.CLI_NOME })
        setShowClientesLoc(false);
    }

    function clientesLocOpen(event) {
        setShowClientesLoc(true);
    }

    //funcoes do modal de equipamentos.
    function equipamentosLocClose(event, data) {
        setServico({...servico, 
            'SER_EQUIPAMENTO': data.EQP_CODIGO, 
            'EQP_ANOFAB': data.EQP_ANOFAB, 
            'EQP_ANOMOD': data.EQP_ANOMOD, 
            'EQP_CHASSI': data.EQP_CHASSI, 
            'EQP_COMBUSTIVEL': data.EQP_COMBUSTIVEL, 
            'EQP_COR': data.EQP_COR, 
            'EQP_DESCRICAO': data.EQP_DESCRICAO, 
            'EQP_MARCA': data.EQP_MARCA, 
            'EQP_MODELO': data.EQP_MODELO, 
            'EQP_SERIE': data.EQP_SERIE 
        });
        setShowEquipamentosLoc(false);
    }

    function equipamentosLocOpen(event) {
        setShowEquipamentosLoc(true);
    }

    //funcoes do modal de vendedores.
    function vendedoresLocClose(event, data) {
        setServico({...servico, 'SER_TECNICO': data.VEN_CODIGO, 'VEN_NOME': data.VEN_NOME })
        setShowVendedoresLoc(false);
    }

    function vendedoresLocOpen(event) {
        setShowVendedoresLoc(true);
    }

    function clientesFormClose() {
        setShowClientesForm(false);
    }
        
    function clientesFormSave(clienteAtualizado) {
        // setCliente(clienteAtualizado);
        // setServico({...servico, 'SER_CLIENTE': data.CLI_CODIGO, 'CLI_NOME': data.CLI_NOME })
        // const updateServico = (prevServico) => {
        //     const newServico = {...prevServico, SER_CLIENTE: clienteAtualizado.CLI_CODIGO, CLI_NOME: clienteAtualizado.CLI_NOME };
        //     return newServico;
        // }
        // setServico(updateServico);
        setServico({...servico, SER_CLIENTE: clienteAtualizado.CLI_CODIGO, CLI_NOME: clienteAtualizado.CLI_NOME });
    }

    // useEffect(() => {
    //     //o uso da funcao abaixo evita ter que usar no array de dependencias.
    //     const updateServico = (prevServico) => {
    //         const newServico = {...prevServico, SER_CLIENTE: cliente.CLI_CODIGO, CLI_NOME: cliente.CLI_NOME };
    //         return newServico;
    //     }
    //     setServico(updateServico);
    // },[cliente]);

    function equipamentosFormClose() {
        setShowEquipamentosForm(false);
    }
        
    function equipamentosFormSave(equipamentoAtualizado) {
        setServico({
            ...servico, 
            SER_EQUIPAMENTO: equipamentoAtualizado.EQP_CODIGO, 
            EQP_CHASSI: equipamentoAtualizado.EQP_CHASSI,
            EQP_COMBUSTIVEL: equipamentoAtualizado.EQP_COMBUSTIVEL,
            EQP_COR: equipamentoAtualizado.EQP_COR,
            EQP_DESCRICAO: equipamentoAtualizado.EQP_DESCRICAO,
            EQP_MARCA: equipamentoAtualizado.EQP_MARCA,
            EQP_MODELO: equipamentoAtualizado.EQP_MODELO,
            EQP_SERIE: equipamentoAtualizado.EQP_SERIE,
        });
    }

    return <>
        <div className="bg-dark fixed-top">
            <div className="container-fluid limit-width">
                <div className="row">
                    <div className="col-12 limit-height d-flex justify-content-between align-items-center">
                        <div className="link-icone"><Link to='/home'><i className="fa fa-2x fa-fw fa-chevron-left"></i></Link></div>
                        <div className="flex-grow-1"><p className="text-truncate text-center text-light fw-bolder mt-3">ORDENS DE SERVIÇO</p></div>
                        <div className="link-icone me-3"><Link to='' onClick={() => setShowModal(true)}><i className="fa fa-2x fa-fw fa-plus"></i></Link></div>
                        <div className="link-icone"><Link to='' onClick={() => setShowModalFiltros(true)}><i className="fa fa-2x fa-fw fa-sliders-h"></i></Link></div>
                    </div>
                </div>
            </div>
        </div>

        <IfComponent condicional={ listagem.length > 0}>
            <div className={'container limit-width p-0 topspace-50'}>
                {                    
                    listagem.map(servico => (
                        <div className="card rounded-0 border-0 border-bottom w-100" key={servico.SER_CODIGO}>
                            <div className="row px-2 my-2">
                                <div className="col-12 text-truncate d-flex justify-content-between">
                                    <div className="text-truncate">
                                    <Link to={"/servico/"+servico.SER_CODIGO} className="link-preto stretched-link">
                                        <span className="fw-bold">{servico.CLI_NOME}</span>
                                    </Link>
                                    </div>
                                    <span className="fw-bold small badge bg-primary rounded-pill">{servico.EQP_SERIE}</span>
                                </div>
                                {/* <div className="row">                                */}
                                    <div className="col-12 lh-2 text-truncate d-flex justify-content-between align-items-center">
                                        {/* <span className="small">{servico.VEN_NOME}</span> */}
                                        <span className="small">{servico.EQP_DESCRICAO}</span>
                                    </div>
                                {/* </div> */}
                                {/* <div className="row">                                */}
                                    <div className="col-12 mb-1 lh-1 d-flex justify-content-between align-items-center">
                                        <span className="small">{servico.VEN_NOME}</span>
                                        <div>
                                            <IfComponent condicional={servico.SER_ANDAMENTO==="AGUARDANDO APROVACAO"}>
                                                <span className="text-light badge bg-dark rounded-pill">{servico.SER_ANDAMENTO}</span>
                                            </IfComponent>
                                            <IfComponent condicional={servico.SER_ANDAMENTO==="AGUARDANDO INICIO"}>
                                                <span className="text-light badge bg-primary rounded-pill">{servico.SER_ANDAMENTO}</span>
                                            </IfComponent>
                                            <IfComponent condicional={servico.SER_ANDAMENTO==="AGUARDANDO RETIRADA"}>
                                                <span className="text-light badge bg-info rounded-pill">{servico.SER_ANDAMENTO}</span>
                                            </IfComponent>
                                            <IfComponent condicional={servico.SER_ANDAMENTO==="EM TRABALHO"}>
                                                <span className="text-light badge bg-warning rounded-pill">{servico.SER_ANDAMENTO}</span>
                                            </IfComponent>
                                            <IfComponent condicional={servico.SER_ANDAMENTO==="FINALIZADA"}>
                                                <span className="text-light badge bg-success rounded-pill">{servico.SER_ANDAMENTO}</span>
                                            </IfComponent>
                                            <IfComponent condicional={servico.SER_ANDAMENTO==="PAUSADA"}>
                                                <span className="text-light badge bg-danger rounded-pill">{servico.SER_ANDAMENTO}</span>
                                            </IfComponent>
                                        </div>
                                        {/* <div>
                                            <IfComponent condicional={servico.SER_STATUS==="A"}>
                                                <span className="text-light badge bg-primary rounded-pill">{servico.SER_STATUS}</span>
                                            </IfComponent>
                                            <IfComponent condicional={servico.SER_STATUS==="B"}>
                                                <span className="badge bg-info rounded-pill">{servico.SER_STATUS}</span>
                                            </IfComponent>
                                            <IfComponent condicional={servico.SER_STATUS==="F"}>
                                                <span className="text-light badge bg-success rounded-pill">{servico.SER_STATUS}</span>
                                            </IfComponent>
                                        </div> */}
                                        {/* <span className="small">{servico.SER_CODIGO}</span>
                                        <span className="text-muted small">{servico.SER_DTENTRADA}</span>
                                        <span className="text-muted small">{servico.SER_DTPREVISAO}</span> */}
                                        {/* <span className="text-danger fw-bold small">
                                            <NumberFormat 
                                                value={parseFloat(servico.SER_VALORTOTAL)}
                                                // isNumericString={true}
                                                displayType="text"
                                                prefix="R$ "
                                                thousandsGroupStyle="thousand"
                                                decimalSeparator=","
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator="."
                                                allowNegative={true}
                                            />&nbsp;
                                        </span> */}
                                    </div>
                                {/* </div> */}
                            </div>
                        </div>
                    ))
                }   
            </div>
        </IfComponent>        
        <Sentinela onIntersect={carregarMais} carregando={carregando} final={final} length={listagem.length} />

        <Modal show={showModal} fullscreen={true} backdrop="static">
            <Modal.Header className="bg-dark text-light p-0 limit-height">
                <div className="container limit-width d-flex justify-content-between align-items-center">
                    <Modal.Title><h5 className="modal-title">NOVA O.S.</h5></Modal.Title>
                    <div>
                        <button className="btn btn-success me-2" onClick={() => setShowClientesForm(true)}>Cadastrar Cliente</button>
                        <button className="btn btn-success" onClick={() => setShowEquipamentosForm(true)}>Cadastrar Equipamento</button>
                    </div>
                </div>
            </Modal.Header>

            <Modal.Body className="p-0">
                <div className="container limit-width mt-2">
                    <div className="row gx-1 p-inputtext-sm pett-input">

                        <div className="col-12 mb-1">
                            <div className="p-inputgroup">
                                <span className="p-float-label pett-label w-25">
                                    <InputText name="SER_CLIENTE" value={servico.SER_CLIENTE} readOnly/>
                                    <label htmlFor="SER_CLIENTE">CLIENTE</label>
                                </span>
                                <InputText className="w-75" name="CLI_NOME" value={servico.CLI_NOME} readOnly/>
                                <ButtonP className="px-3 p-button-success" icon="pi pi-search" onClick={(e) => clientesLocOpen(e)}/>
                            </div>          
                        </div>

                        <div className="col-12 mb-1">
                            <div className="p-inputgroup">
                                <span className="p-float-label pett-label w-25">
                                    <InputText name="SER_TECNICO" value={servico.SER_TECNICO || ''} readOnly/>
                                    <label htmlFor="SER_TECNICO">TÉCNICO</label>
                                </span>
                                <InputText className="w-75" name="VEN_NOME" value={servico.VEN_NOME || ''} readOnly/>
                                <ButtonP className="px-3 p-button-success" icon="pi pi-search" onClick={(e) => vendedoresLocOpen(e)}/>
                            </div>          
                        </div>

                        <div className="col-12 mb-1">
                            <div className="p-inputgroup">
                                <span className="p-float-label pett-label w-25">
                                    <InputText name="SER_EQUIPAMENTO" value={servico.SER_EQUIPAMENTO || ''} readOnly/>
                                    <label htmlFor="SER_EQUIPAMENTO">EQUIPAMENTO</label>
                                </span>
                                <InputText className="w-75" name="EQP_DESCRICAO" value={servico.EQP_DESCRICAO || ''} readOnly/>
                                <ButtonP className="px-3 p-button-success" icon="pi pi-search" onClick={(e) => equipamentosLocOpen(e)}/>
                            </div>          
                        </div>

                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="EQP_MARCA" value={servico.EQP_MARCA || ''} readOnly disabled/>
                                <label htmlFor="EQP_MARCA">MARCA</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="EQP_MODELO" value={servico.EQP_MODELO || ''} readOnly disabled/>
                                <label htmlFor="EQP_MODELO">MODELO</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="EQP_SERIE" value={servico.EQP_SERIE || ''} readOnly disabled/>
                                <label htmlFor="EQP_SERIE">SÉRIE / PLACA</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="EQP_CHASSI" value={servico.EQP_CHASSI || ''} readOnly disabled/>
                                <label htmlFor="EQP_CHASSI">CHASSI</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="EQP_COMBUSTIVEL" value={servico.EQP_COMBUSTIVEL || ''} readOnly disabled/>
                                <label htmlFor="EQP_COMBUSTIVEL">COMBUSTIVEL</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="EQP_COR" value={servico.EQP_COR || ''} readOnly disabled/>
                                <label htmlFor="EQP_COR">COR</label>
                            </span>
                        </div>
                        <div className="col-12 mb-1">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="SER_ACESSORIOS" value={servico.SER_ACESSORIOS || ''} onChange={(e) => setCampo(e,'servico',true)} />
                                <label htmlFor="SER_ACESSORIOS">ACESSÓRIOS</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputNumber className="w-100" name="SER_KMATUAL" value={servico.SER_KMATUAL || ''} onValueChange={(e) => setCampo(e,'servico',false)} useGrouping={false}/>
                                <label htmlFor="SER_KMATUAL">KM ATUAL</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputMask className="w-100" name="SER_DTPREVISAO" mask="99/99/9999" value={servico.SER_DTPREVISAO || ''} onChange={(e) => setCampo(e,'servico',true)} ></InputMask>
                                <label htmlFor="SER_DTPREVISAO">DATA PREVISÃO</label>
                            </span>
                        </div>
                        <div className="col-12">
                            <span className="p-float-label pett-label">
                                <InputTextarea className="w-100" name="SER_DEFEITO" value={servico.SER_DEFEITO || ''} onChange={(e) => setCampo(e,'servico',false)} autoResize />
                                <label htmlFor="SER_DEFEITO">DEFEITO ALEGADO</label>
                            </span>
                        </div>

                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer className='bg-dark text-light p-0 limit-height d-flex justify-content-center'>
                <div className="container limit-width btn-group p-0">
                    <Button variant="danger" onClick={() => setShowModal(false)}>Cancelar</Button>
                    <Button variant="success" onClick={() => btnSalvar()}><i className="fa fa-fw fa-check"></i> Salvar</Button>
                </div>
            </Modal.Footer>
        </Modal>

        <Modal show={showModalFiltros} fullscreen={true} backdrop="static">
            <Modal.Header className="bg-dark text-light p-0 limit-height">
                <div className="container-fluid limit-width">
                    <Modal.Title><h5 className="modal-title">FILTROS</h5></Modal.Title>
                </div>
            </Modal.Header>

            <Modal.Body className="p-0">
                <div className="container limit-width mt-2">
                    <div className="row g-0">

                        <div className="col-12 p-inputtext-sm pett-input">
                            <span className="p-float-label pett-label mb-1">
                                <Dropdown className="w-100" name="status" value={filtros.status || ''} options={selectStatus} onChange={(e) => setCampo(e,'filtros',false)}/>
                                <label htmlFor="status">STATUS</label>
                            </span>
                        </div>

                        <div className="col-12 p-inputtext-sm pett-input">
                            <span className="p-float-label pett-label mb-1">
                                <Dropdown className="w-100" name="andamento" value={filtros.andamento || ''} options={selectAndamento} onChange={(e) => setCampo(e,'filtros',false)}/>
                                <label htmlFor="andamento">ANDAMENTO</label>
                            </span>
                        </div>

                        <div className="col-12 p-inputtext-sm pett-input">
                            <span className="p-float-label pett-label mb-1">
                                <Calendar className="w-100" name="dataInicial" inputId="dataInicial" value={new Date(filtros.dataInicial)} onChange={(e) => setCampo(e,'filtros',false)} dateFormat="dd/mm/yy" locale='pt' showButtonBar autoZIndex />
                                <label htmlFor="dataInicial">DATA INICIAL</label>
                            </span>
                        </div>
                        
                        <div className="col-12 p-inputtext-sm pett-input">
                            <span className="p-float-label pett-label mb-1">
                                <Calendar className="w-100" name="dataFinal" inputId="dataFinal" value={new Date(filtros.dataFinal)} onChange={(e) => setCampo(e,'filtros',false)} dateFormat="dd/mm/yy" locale='pt' showButtonBar autoZIndex />
                                <label htmlFor="dataFinal">DATA FINAL</label>
                            </span>
                        </div>

                        <div className="col-12 p-inputtext-sm pett-input">
                            <span className="p-float-label pett-label mb-1">
                                <InputText className="w-100" name="pesquisa" value={filtros.pesquisa} onChange={(e) => setCampo(e,'filtros',true)} onKeyDown={(e) => localizarKeyDown(e)}/>
                                <label htmlFor="pesquisa">LOCALIZAR</label>
                            </span>
                        </div>
                                                
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer className='bg-dark limit-height text-light p-0 d-flex justify-content-center'>
                <div className="container limit-width p-0">
                    <div className="btn-group w-100">
                        <Button className="" variant="danger" onClick={() => setShowModalFiltros(false)}>Cancelar</Button>
                        <Button className="" variant="warning" onClick={() => resetarFiltros(false)}>Resetar</Button>
                        <Button className="" variant="success" onClick={() => Pesquisar()}><i className="fa fa-fw fa-check"></i> Filtrar</Button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>

        <ClientesLoc showModal={showClientesLoc} onCloseModal={clientesLocClose}/>
        <EquipamentosLoc showModal={showEquipamentosLoc} onCloseModal={equipamentosLocClose}/>
        <VendedoresLoc showModal={showVendedoresLoc} onCloseModal={vendedoresLocClose}/>
        <ClientesForm show={showClientesForm} registro={null} fechar={clientesFormClose} salvar={clientesFormSave} />
        <EquipamentosForm show={showEquipamentosForm} registro={servico} fechar={equipamentosFormClose} salvar={equipamentosFormSave} />
    </>
}

export default Servicos;