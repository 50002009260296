import React, { useState } from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { InputMask } from 'primereact/inputmask';
import { InputText } from 'primereact/inputtext';

import IfComponent from '../IfComponent';
import './ConsultarPlaca.css';
import Swal from 'sweetalert2';

function ConsultarPlaca({show,fechar,salvar}) {
    const [ pesquisa, setPesquisa ] = useState({ placa: '' });
    const [ resultado, setResultado ] = useState({ MARCA: '', error: '', extra: {} });
    const [ erro, setErro ] = useState(null);
    const [ consultando, setConsultando ] = useState(false);
    // const inputMaskRef = useRef(null);

    // useEffect(() => {
    //     if (inputMaskRef.current) {
    //         inputMaskRef.current.element.focus();
    //         inputMaskRef.current.element.select(); // Seleciona todo o texto
    //     }
    // }, []);

    // Função para autenticar e obter o token bearer
    async function autenticar() {
        try {
            const formData = new FormData();
            formData.set('email','rogerio@pett.com.br');
            formData.set('password','M41c01macdc!');
            const response = await axios.post('https://cluster.apigratis.com/api/v2/login',formData);
            setErro(null);
            return response.data.authorization.token;
        } catch (error) {         
            console.log('CATCH',error.response.data);
            setErro('ERRO: '+error.response.data.message);
        }
    };

    // Função para consultar placa de veículo
    async function consultar(token) {
        if (token) {
            try {
                const formData = {
                    'placa': pesquisa.placa
                }
                const response = await axios.post(`https://cluster.apigratis.com/api/v2/vehicles/dados`, formData, {
                    headers: {
                        'Content-Type': 'application/json',
                        'DeviceToken': 'f45ffcda-a77c-46a7-a337-9330606e1390',
                        'Authorization': `Bearer ${token}`
                    }
                });
                setResultado(response.data.response);
                if (response.data.response.extra === undefined) {
                    setResultado((prevResultado) => ({
                        ...prevResultado,
                        placa_consultada: pesquisa.placa,
                        extra: 'Valor Padrão',
                    }));
                }
                setErro(null);
            } catch (error) {
                console.log('CATCH',error.response.status)
                // setErro('Erro: '+error);
                setResultado({ MARCA: '', error: '', placa_consultada: '', extra: {} });
            }
        }
    };

    async function executaConsulta() {
        setConsultando(true);
        const newToken = await autenticar();
        await consultar(newToken);
        setConsultando(false);
    }

    function salvarEquipamento() {
        if (!resultado.MARCA) {
            Swal.fire({title: 'Ah nãooooo!', text: 'Consulte uma placa válida.', icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
        } else {
            if (!pesquisa.placa) {
                Swal.fire({title: 'Ah nãooooo!', text: 'Campo placa vazio.', icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
            } else {
                salvar({...resultado, placa_consultada: pesquisa.placa.toUpperCase()}); //funcao que foi passada no props
            }
        }
    }

    function cancelar() {
        setResultado({ MARCA: '', error: '', placa_consultada: '', extra: {} });
        setPesquisa({ placa: '' });
        fechar(); //funcao que foi passada por props.
    }    

    function setCampo(e,objeto,upper) {
        const { name, value } = e.target;
        let valor='';
        if (upper) {
            valor = value?value.toUpperCase():'';
        } else {
            valor = value;
        }
        if (objeto==="pesquisa") {
            setPesquisa({...pesquisa, [name]: valor});
        }
    }

    return <>
        <Modal show={show} fullscreen={true} backdrop="static">
            <Modal.Header className="bg-dark text-light p-0 limit-height">
                <div className="container limit-width">
                    <Modal.Title><h5 className="modal-title">CONSULTAR PLACA</h5></Modal.Title>
                </div>
            </Modal.Header>

            <Modal.Body className="p-0">
                <div className="container limit-width">
                    <div className="d-flex justify-content-center">
                        <div className="placa mt-3">
                            <InputMask className="w-100 text-center bg-transparent border-0 placa-input" name="placa" mask="aaa9*99" value={pesquisa.placa || ''} onChange={(e) => setCampo(e,'pesquisa',false)} />
                        </div>
                    </div>
                    <div className="w-100 d-flex justify-content-center mb-3">
                        {/* <button onClick={autenticar}>Obter Token</button> */}
                        {   consultando
                                ? <button className="btn btn-lg btn-primary disabled"><i className="fa fa-fw fa-spin fa-spinner"></i> Consultando...</button>
                                : <button className="btn btn-lg btn-primary" onClick={executaConsulta}><i className="fa fa-fw fa-search"></i> Consultar</button>
                        }
                    </div>
                    {/* <IfComponent condicional={ Object.keys(resultado).length !== 0 }> */}
                    <IfComponent condicional={ resultado.error!=='' }>
                        <div>
                            <p className="w-100 text-center">{resultado.message}</p>
                        </div>
                    </IfComponent>
                    {/* <IfComponent condicional={ resultado.MARCA!==undefined && resultado.MARCA!=='' }> */}
                    <IfComponent condicional={ resultado.MARCA }>
                        <div>
                            <div className="row mb-0 mt-0 gx-1 p-inputtext-sm pett-input">
                                <div className="col-12 mb-1">
                                    <span className="p-float-label pett-label">
                                        <InputText className="w-100" name="EQP_MARCA" value={resultado.MARCA || ''} readOnly />
                                        <label htmlFor="EQP_MARCA">MARCA</label>
                                    </span>
                                </div>
                                <div className="col-12 mb-1">
                                    <span className="p-float-label pett-label">
                                        <InputText className="w-100" name="EQP_MODELO" value={resultado.MODELO || ''} readOnly />
                                        <label htmlFor="EQP_MODELO">MODELO</label>
                                    </span>
                                </div>
                                <div className="col-6 mb-1">
                                    <span className="p-float-label pett-label">
                                        <InputText className="w-100" name="EQP_ANOFAB" value={resultado.ano || ''} readOnly />
                                        <label htmlFor="EQP_ANOFAB">ANO FAB.</label>
                                    </span>
                                </div>
                                <div className="col-6 mb-1">
                                    <span className="p-float-label pett-label">
                                        <InputText className="w-100" name="EQP_ANOMOD" value={resultado.ano_modelo || ''} readOnly />
                                        <label htmlFor="EQP_ANOMOD">ANO MOD.</label>
                                    </span>
                                </div>
                                <div className="col-6 mb-1">
                                    <span className="p-float-label pett-label">
                                        <InputText className="w-100" name="EQP_COR" value={resultado.cor || ''} readOnly />
                                        <label htmlFor="EQP_COR">COR</label>
                                    </span>
                                </div>
                                <div className="col-6 mb-1">
                                    <span className="p-float-label pett-label">
                                        <InputText className="w-100" name="EQP_COMBUSTIVEL" value={resultado.combustivel || ''} readOnly />
                                        <label htmlFor="EQP_COMBUSTIVEL">COMBUSTÍVEL</label>
                                    </span>
                                </div>
                                <div className="col-12 mb-1">
                                    <span className="p-float-label pett-label">
                                        <InputText className="w-100" name="EQP_CHASSI" value={resultado.extra.chassi || ''} readOnly />
                                        <label htmlFor="EQP_CHASSI">CHASSI</label>
                                    </span>
                                </div>
                                <div className="col-12 mb-1">
                                    <span className="p-float-label pett-label">
                                        <InputText className="w-100" name="EQP_INFO" value={resultado.info || ''} readOnly />
                                        <label htmlFor="EQP_INFO">INFO</label>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </IfComponent>
                    { erro && <p className="text-center">{erro}</p> }
                </div>
            </Modal.Body>

            <Modal.Footer className='bg-dark text-light p-0 limit-height d-flex justify-content-center'>
                <div className="container limit-width btn-group p-0">
                    <Button className="w-100" variant="danger" onClick={cancelar}>Cancelar</Button>
                    { 
                        consultando
                            ? <Button className="w-100 disabled" variant="success"><i className="fa fa-fw fa-spin fa-spinner"></i> Consultando...</Button>
                            : <Button className="w-100" variant="success" onClick={salvarEquipamento}><i className="fa fa-fw fa-check"></i> Salvar</Button>
                    }
                </div>
            </Modal.Footer>
        </Modal>
    </>
  
  
}

export default ConsultarPlaca;