import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { InputText } from 'primereact/inputtext';

// funcoes proprias.
import Api from '../Api';
import { AuthContext } from '../contextos/Auth';
import IfComponent from '../componentes/IfComponent';
import VendasItensConferencia from '../componentes/VendasItensConferencia';

function VendaConferencia(props) {
    const { logout } = useContext(AuthContext);
    const { id } = useParams();
    const navigate = useNavigate();
    const [ venda, setVenda ] = useState({});
    const [ itens, setItens ] = useState([]);
    const [ quantidade, setQuantidade ] = useState('1');
    const [ codigoBarras, setCodigoBarras ] = useState('');

    function VerVenda() {
        var formData = new FormData();
        formData.set('op', 'verconferencia');
        formData.set('venda', id);

        Api.post('vendas.php',formData)
        .then((response) => {
            setVenda(response.data.msg);
            if (response.data.erro==='N') {
                setVenda(response.data.msg);
                setItens(response.data.msg.ITENS);
            } else {
                setVenda({VED_CODIGO:0});
                setItens({});
                if (response.data.token==='expirado') {
                    logout();
                }
            }
        })
        .catch((response) => {
            console.log('CATCH',JSON.stringify(response.data));
            Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});  
        });
    }   
   
    useEffect(() => {
        VerVenda();
        // eslint-disable-next-line
    },[]);

    function finalizarConferencia() {
        const itensNaoConferidos = itens.filter(item => (item.STATUSCONFERENCIA === '---'));
        if (itensNaoConferidos.length > 0) {
            Swal.fire({title: 'Ooops...', text: 'Ainda existem itens não conferidos.', icon: 'error', showConfirmButton: false, timer: 1500});
        } else {
            Swal.fire({
                position: 'center',
                icon: 'question',
                title: 'Tudo conferido?',
                text: 'Finalizar a conferencia do pedido '+venda.VED_CODIGO+'?',
                showCancelButton: true,
                confirmButtonText: 'Sim, pode finalizar!',
                cancelButtonText: 'Ainda Não!',
                confirmButtonColor: '#212529'
            }).then((result) => {
                if (result.isConfirmed) {
                    const formData = new FormData();
                    formData.set('op','conferido');
                    formData.set('venda',venda.VED_CODIGO || '');
                    Api.post('vendas.php',formData).then((response) => {                
                        if (response.data.erro==='N') {
                            Swal.fire({title: 'Yessss!', text: 'Conferência finalizada com sucesso.', icon: 'success', showConfirmButton: false, timer: 1500});
                            setVenda(response.data.msg)
                        } 
                        if (response.data.erro==='S') {
                            response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
                        }
                    })
                }
            });
        }
    }

    function ConferirItem() {
        if (codigoBarras !== '') {
            //find retorna um objeto.
            //filter retorna um array de objetos.
            let itemFiltrado = itens.find(item => (String(item.VEI_PRODUTO) === String(parseInt(codigoBarras)) && item.STATUSCONFERENCIA==='---')) || '';
            setCodigoBarras('');
            
            if (itemFiltrado==='') {
                //if (itemFiltrado.VEI_CODIGO)==='') {
                    Swal.fire({title: 'Ooops...', text: 'Produto não encontrado ou já conferido.', icon: 'warning', showConfirmButton: false, timer: 1500});
            } else {                 
                const demaisItens = itens.filter(item => (  String(item.VEI_CODIGO) !== String(itemFiltrado.VEI_CODIGO)));

                let qtde = parseFloat(itemFiltrado.QTDECONFERIDA)+quantidade;

                if (qtde >= parseFloat(itemFiltrado.VEI_QUANTIDADE)) {
                    itemFiltrado.STATUSCONFERENCIA='OK!';
                }
                
                if (qtde > parseFloat(itemFiltrado.VEI_QUANTIDADE)) {
                    qtde = parseFloat(itemFiltrado.VEI_QUANTIDADE);
                }

                if (qtde > itemFiltrado.VEI_QUANTIDADE) {
                    Swal.fire({title: 'Ooops...', text: 'Já conferimos esse item.', icon: 'warning', showConfirmButton: false, timer: 1500});
                } else {

                    if ((parseFloat(itemFiltrado.QTDECONFERIDA)+quantidade) > parseFloat(itemFiltrado.VEI_QUANTIDADE)) {
                        itemFiltrado.QTDECONFERIDA = parseFloat(itemFiltrado.VEI_QUANTIDADE);
                        itemFiltrado.STATUSCONFERENCIA='OK!';
                    } else {
                        itemFiltrado.QTDECONFERIDA = parseFloat(itemFiltrado.QTDECONFERIDA)+quantidade;
                    }
                    
                    demaisItens.unshift(itemFiltrado);
                    const demaisItensSort = demaisItens.sort((c1,c2) => (c1.VEI_SEQUENCIA > c2.VEI_SEQUENCIA) ? 1 : (c1.VEI_SEQUENCIA < c2.VEI_SEQUENCIA) ? -1 : 0);
                    //     //(c1, c2) => (c1.capacity < c2.capacity) ? 1 : (c1.capacity > c2.capacity) ? -1 : 0);
                    setItens(demaisItensSort);
                    //setItens(itemFiltrado);
                    //nao entendi... como está funcionando isso?
                }
            }
        }
    }

    const codigoBarrasKeyDown = (e) => {
        if (e.keyCode === 13) {
            ConferirItem();
        }
    }  
  
    return <>    
        <div className="bg-dark fixed-top">
            <div className="container-fluid limit-width p-0">
                <div className="row">
                    <div className="col-12 limit-height d-flex justify-content-between align-items-center">
                        <div className="">
                            <Link to="" onClick={() => navigate(-1)} className="link-icone"><i className="fa fa-2x fa-fw fa-chevron-left mx-2"></i></Link>
                        </div>
                        <div className="flex-grow-1 text-truncate"><p className="text-truncate fw-bold text-center text-light mt-3">PEDIDO DE VENDA</p></div>
                        <div className="">
                            <span className="link-icone"><i className="fa fa-2x fa-fw fa-nenhum mx-2"></i></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <IfComponent condicional={venda.VED_CODIGO<=0}>
            <div className="container limit-width vh-100 d-flex justify-content-center align-items-center">
                <p className="text-center lead"><span style={{fontSize: '7em'}} className="text-muted"><i className="fa fa-fw fa-record-vinyl"></i></span><br/>Não foi possível localizar o pedido.</p>
            </div>  
        </IfComponent>
        <IfComponent condicional={venda.VED_CODIGO>0}>
            <div className="container-fluid limit-width mt-5 pt-2">
                <ul className="list-group mb-3">  
                    <li className="list-group-item p-2">    
                        <div className="row">      
                            <div className="col-4">
                                <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">CÓDIGO</sup></p><p className="lh-1 m-0">{venda.VED_CODIGO}</p>
                            </div>      
                            <div className="col-4">
                                <IfComponent condicional={venda.VED_CONFERIDO==='N'}>
                                    <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">CONFERIDO</sup></p><p className="lh-1 m-0 fw-bold text-primary">NÃO</p>
                                </IfComponent>
                                <IfComponent condicional={venda.VED_CONFERIDO==='S'}>
                                    <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">CONFERIDO</sup></p><p className="lh-1 m-0 fw-bold text-success">SIM</p>
                                </IfComponent>
                            </div>      
                            <div className="col-4">
                                <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">CONFERIDO POR</sup></p><p className="lh-1 m-0 fw-bold text-primary">{venda.VED_CONFERIDOPOR}</p>
                            </div>
                        </div>  
                    </li> 
                    <li className="list-group-item p-2"><p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">CLIENTE</sup></p><p className="lh-1 m-0">{venda.VED_CLIENTE+' - '+venda.VED_NOME}</p></li>  
                    <li className="list-group-item p-2">    
                        <div className="row">      
                            <div className="col-6">
                                <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">DATA CADASTRO</sup></p><p className="lh-1 m-0">{venda.VED_DTCADASTRO} {venda.VED_HRCADASTRO}</p>
                            </div>      
                            <div className="col-6">
                                <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">DATA CONFERENCIA</sup></p><p className="lh-1 m-0">{venda.VED_CONFERIDODATA}</p>
                            </div>    
                        </div>  
                    </li> 
                </ul>
            </div>
        </IfComponent>

        <div className="container-fluid limit-width">
            <div className="row">
                <IfComponent condicional={itens.length > 0 && venda.VED_CONFERIDO==='N'}>
                    <div className="col-12 mb-3">
                        <Link to="" className="btn btn-primary w-100" onClick={() => finalizarConferencia()}><i className="fa fa-fw fa-check me-2"></i> Finalizar Conferência</Link>
                    </div>
                    <div className="col-12 d-flex justify-content-between align-items-center bg-secondary bg-opacity-25 limit-height">
                        <div className="me-2">
                            <p className="text-truncate text-black fs-6 fw-bolder mt-3">Qtde / Código</p>
                        </div>
                        <div className="p-inputgroup p-inputtext-sm">
                            <InputText className="w-25" name="QUANTIDADE" placeholder="Digite o código e pressione ENTER" value={quantidade || ''} onChange={(e) => setQuantidade(e.target.value)}/>
                            <InputText className="w-100" name="CODIGOBARRAS" placeholder="Digite o código e pressione ENTER" value={codigoBarras || ''} onChange={(e) => setCodigoBarras(e.target.value)} onKeyDown={(e) => codigoBarrasKeyDown(e)}/>
                        </div>          
                    </div>
                </IfComponent>
                <IfComponent condicional={itens.length > 0 && venda.VED_CONFERIDO==='S'}>
                    <div className="col-12 d-flex justify-content-between align-items-center bg-secondary bg-opacity-25 limit-height">
                        <div className="me-2"><p className="text-truncate text-black fs-6 fw-bolder mt-3">Itens</p></div>
                    </div>
                </IfComponent>
            </div>
        </div>

        <IfComponent condicional={itens.length > 0}>
            <div className="container-fluid limit-width py-2">
                <div className="mb-5">
                    {                    
                        itens.map(item => (
                            <VendasItensConferencia
                                key={item.VEI_CODIGO}
                                VEI_PRODUTO={item.VEI_PRODUTO}
                                VEI_DESCRICAO={item.VEI_DESCRICAO}
                                VEI_QUANTIDADE={item.VEI_QUANTIDADE}
                                VEI_SEQUENCIA={item.VEI_SEQUENCIA}
                                VEI_UNIDADE={item.VEI_UNIDADE}
                                QTDECONFERIDA={item.QTDECONFERIDA}
                                STATUSCONFERENCIA={item.STATUSCONFERENCIA}
                            />
                        ))
                    }
                </div>             
            </div>             
        </IfComponent>

    </>
}

export default VendaConferencia;