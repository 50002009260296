import React from 'react';
//import { Link } from 'react-router-dom';
import { NumericFormat } from "react-number-format";

function ServicosItens(props) {
    return <>
        <div className="card w-100 rounded-0 border-0 border-bottom">
            <div className="row g-0">
                <div className="col-12 m-0 p-2 d-flex align-items-center">
                    <div className="row text-truncate w-100 g-0">
                        {/* <div className="col-12 text-truncate fs-6 m-0 lh-1"><Link className="link-preto stretched-link" onClick={props.edicao} to="">{props.VEI_DESCRICAO}</Link></div> */}
                        <div className="col-12 text-truncate text-black fs-6 m-0 lh-1">{props.SEI_DESCRICAO}</div>
                        {/* <div className="col-12 text-truncate text-secondary small lh-1 border ">
                            <div className="row w-100 border border-danger "> */}
                        <div className="col-1 text-secondary small lh-1 mt-1">
                            {props.SEI_SEQUENCIA}    
                        </div>
                        <div className="col-2 text-secondary small lh-1 mt-1">
                            {props.SEI_PRODUTO}    
                        </div>
                        <div className="col-1 text-secondary small lh-1 mt-1">
                            {props.SEI_UNIDADE}
                        </div>
                        <div className="col-2 text-end text-success small lh-1 mt-1">
                            <NumericFormat 
                                value={parseFloat(props.SEI_QUANTIDADE)}
                                // isNumericString={true}
                                displayType="text"
                                prefix=""
                                thousandsGroupStyle="thousand"
                                decimalSeparator=","
                                decimalScale={4}
                                fixedDecimalScale={true}
                                thousandSeparator="."
                                allowNegative={true}
                            />                               
                        </div>
                        <div className="col-3 text-end text-secondary small lh-1 mt-1 fw-bold">
                            <NumericFormat 
                                value={parseFloat(props.SEI_VALORUNIT)}
                                // isNumericString={true}
                                displayType="text"
                                prefix="R$ "
                                thousandsGroupStyle="thousand"
                                decimalSeparator=","
                                decimalScale={2}
                                fixedDecimalScale={true}
                                thousandSeparator="."
                                allowNegative={true}
                            />
                        </div>
                        <div className="col-3 text-end text-primary small lh-1 mt-1 fw-bold">
                            <NumericFormat 
                                value={parseFloat(props.SEI_VALORTOTAL)}
                                // isNumericString={true}
                                displayType="text"
                                prefix="R$ "
                                thousandsGroupStyle="thousand"
                                decimalSeparator=","
                                decimalScale={2}
                                fixedDecimalScale={true}
                                thousandSeparator="."
                                allowNegative={true}
                            />
                        </div>
                            {/* </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    </>
} 

export default ServicosItens;