import React, { useState, useContext } from 'react';
import { Navigate } from 'react-router-dom'; 
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';

// funcoes proprias.
import { AuthContext } from '../contextos/Auth';
import { ReactComponent as Logo } from "../Imagens/logo_runtime_black.svg";

function Login() {

    const { logado, usuarioStatus, mensagem, logar } = useContext(AuthContext);

    const [login, setLogin] = useState('');
    const [senha, setSenha] = useState('');
    const [acessando, setAcessando] = useState(false);

    if (usuarioStatus==='I') {
        return <Navigate to='/confirmarcadastro' />
    }

    if (logado) {
        return <Navigate to='/home' />
    }

    async function Acessar() {     
        setAcessando(true);
        await logar(login, senha);      
        setAcessando(false);
    }

    return <div className="container-fluid bg-dark">
        <div className="d-flex align-items-center text-center vh-100 d-flex justify-content-center">
            <div className="limit-width w-100" style={{maxWidth: '350px'}}>
                <Logo className="mb-3" style={{maxWidth: '280px'}} />
                { mensagem!=='' ? <div className="alert alert-danger mb-2" role="alert">{mensagem}</div> : null }

                <div className="row mt-3 gx-2 p-inputtext-sm pett-input">
                    <div className="col-12 mb-2">
                        <span className="p-float-label pett-label-light">
                            <InputText className="w-100 bg-light bg-opacity-10 text-light text-uppercase" name="login" value={login || ''} onChange={(e) => setLogin(e.target.value)} required autoFocus/>
                            <label className="text-light" htmlFor="login">LOGIN</label>
                        </span>
                    </div>
                    <div className="col-12 mb-2 pett-input-dark">
                        <span className="p-float-label pett-label-light">
                            <Password className="w-100" name="senha" value={senha || ''} onChange={(e) => setSenha(e.target.value)} feedback={false} required />
                            <label htmlFor="senha" style={{color: '#fff'}}>SENHA</label>
                        </span>
                    </div>
                </div>
                {   acessando
                    ? <button className="btn btn-lg btn-success w-100 mt-4" type="button" disabled><i className='pi pi-fw pi-spin pi-spinner'></i> Verificando credenciais...</button>
                    : <button className="btn btn-lg btn-success w-100 mt-4" type="button" onClick={Acessar}>Acessar</button>
                }
            </div>
        </div>
    </div>    
}

export default Login;