import React from 'react';
//import { Link } from 'react-router-dom';
import { NumericFormat } from "react-number-format";

function VendasItens(props) {
    return <>
        <div className="card rounded-0 border-0 border-bottom" >
            <div className="row g-0">
                <div className="col-12 m-0 p-2 d-flex align-items-center">
                    <div className="row text-truncate">
                        <div className="col-12 text-truncate text-black fs-6 m-0 lh-2">{props.VEI_DESCRICAO}</div>
                        <div className="col-1 text-secondary small lh-2">
                            {props.VEI_SEQUENCIA}    
                        </div>
                        <div className="col-2 text-secondary small lh-2">
                            {props.VEI_PRODUTO}    
                        </div>
                        <div className="col-1 text-secondary small lh-2">
                            {props.VEI_UNIDADE}
                        </div>
                        <div className="col-2 text-end text-success small lh-2">
                            <NumericFormat 
                                value={parseFloat(props.VEI_QUANTIDADE)}
                                // isNumericString={true}
                                displayType="text"
                                prefix=""
                                thousandsGroupStyle="thousand"
                                decimalSeparator=","
                                decimalScale={4}
                                fixedDecimalScale={true}
                                thousandSeparator="."
                                allowNegative={true}
                            />                               
                        </div>
                        <div className="col-3 text-end text-secondary small lh-2 fw-bold">
                            <NumericFormat 
                                value={parseFloat(props.VEI_VALORUNIT)}
                                // isNumericString={true}
                                displayType="text"
                                prefix="R$ "
                                thousandsGroupStyle="thousand"
                                decimalSeparator=","
                                decimalScale={2}
                                fixedDecimalScale={true}
                                thousandSeparator="."
                                allowNegative={true}
                            />
                        </div>
                        <div className="col-3 text-end text-primary small lh-2 fw-bold">
                            <NumericFormat 
                                value={parseFloat(props.VEI_VALORTOTAL)}
                                // isNumericString={true}
                                displayType="text"
                                prefix="R$ "
                                thousandsGroupStyle="thousand"
                                decimalSeparator=","
                                decimalScale={2}
                                fixedDecimalScale={true}
                                thousandSeparator="."
                                allowNegative={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
} 

export default VendasItens;