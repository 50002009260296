import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Swal from 'sweetalert2';

import IfComponent from './IfComponent';
import Api from '../Api';
import Sentinela from './Sentinela';

function VendedoresLoc(props) {
    const [ listagem, setListagem ] = useState([]);
    const [ carregando, setCarregando ] = useState(false);
    const [ pagina, setPagina ] = useState(0);
    const [ final, setFinal ] = useState(false);
    const [ pesquisa, setPesquisa ] = useState('');

    function listarVendedores(nPagina,bFinal,bLimpar) {
        if (!bFinal) {
            setCarregando(true);    
            const novaPagina=nPagina+1;
            //setPagina((paginaInsideState) => paginaInsideState+1);
                        
            var formData = new FormData();
            formData.set('op', 'listar');
            formData.set('pagina', novaPagina);
            formData.set('pesquisa', pesquisa);
                       
            Api.post('vendedores.php',formData).then((response) => {
                if (response.data.erro==='N') {
                    const novaListagem = [];
                    if (response.data.contador > 0) {
                        if (!bLimpar){
                            //const novaListagem = [...listagem] //novalistagem recebe listagem atual.
                            novaListagem.push(...listagem); //novalistagem recebe listagem atual.
                            setListagem([]);
                        }
                        novaListagem.push(...response.data.msg); //novalistagem recebe mais a nova listagem.
                    } else {
                        //se ja tem uma listagem, continua com ela
                        //se a pesquisa retornou 30 registros, ao dar scroll para a pagina 2 que retorna com 0 registros, continua com a listagem atual.
                        if (novaPagina > 1) { 
                            novaListagem.push(...listagem); 
                        }
                    }

                    //verifica se pode conter mais registros. 
                    //se retornou com 20 registros, pode ser que ainda tenha mais.
                    //se retornou com menos de 30 registros, é pq chegou no final da lista.
                    if (response.data.contador < 30) {
                        setFinal(true);
                    }

                    setListagem(novaListagem);
                    setCarregando(false);
                    setPagina(novaPagina);
                } else {
                    Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
                }
            }).catch(({response}) => {
                setCarregando(false);
            });
        }
    }

    function carregarMais() {
        listarVendedores(pagina,final,false);
    }

    function Pesquisar() {
        setPagina(0);
        setFinal(false);
        setListagem([]);
        listarVendedores(0,false,true);
    };
    
    function Cancelar(e) {
        let d={ 'LOC':false };
        // setData(d);
        props.onCloseModal(e,d);
        //setOpen(false);
    }
    
    function SelecionarVendedor(e,vendedor) {
        let d={ 'LOC': true, ...vendedor }
        // setData(d);
        props.onCloseModal(e,d);
    }

    const localizarKeyDown = (e) => {
        if (e.keyCode === 13) {
            Pesquisar();
        }
    } 

    return <>
        <Modal show={props.showModal} fullscreen={true} backdrop="static">
            <Modal.Header className="bg-dark text-light py-0 limit-height">
                <div className="container limit-width">
                    <Modal.Title><h5 className="modal-title">Localizar Vendedor/Técnico...</h5></Modal.Title>
                </div>
            </Modal.Header>

            <Modal.Body className="p-0">
        
                <div className="bg-dark fixed-top mt-5">
                    <div className="container limit-width">
                        <div className="row">
                            <div className="col-12 limit-height d-flex justify-content-between align-items-center">
                                <div className="w-100">
                                    <input className="form-control border-0 bg-light bg-opacity-10 rounded-pill text-light disable-form-control-focus" type="text" name="texto" value={pesquisa} placeholder="Localizar" onChange={(e) => setPesquisa(e.target.value.toUpperCase())}  onKeyDown={(e) => localizarKeyDown(e)}/>
                                </div>
                                <div className="ms-2"><button className="btn btn-success rounded-pill" onClick={() => { Pesquisar(); }} >Filtrar</button></div>
                            </div>
                        </div>
                    </div>
                </div>

                <IfComponent condicional={ listagem.length > 0}>
                    <div className="mt-5 p-0">
                        <div className="container limit-width mb-3 p-0">
                            {                     
                                listagem.map(vendedor => (
                                    <div className="card rounded-0 border-0 border-bottom" key={vendedor.VEN_CODIGO}>
                                        <div className="card-body text-truncate p-2 py-2">
                                            <Link to="" onClick={ (e) => SelecionarVendedor(e,vendedor) } className="link-preto stretched-link">
                                                <span className="fw-bold">{vendedor.VEN_NOME}</span>
                                            </Link>
                                        </div>
                                    </div>
                                ))
                            }   
                        </div>
                    </div>
                </IfComponent>           
                <Sentinela onIntersect={carregarMais} carregando={carregando} final={final} length={listagem.length} />
            </Modal.Body>


            <Modal.Footer className='bg-dark text-light py-0 limit-height d-flex justify-content-center p-0'>
                <div className="container limit-width p-0">
                    <div className="btn-group w-100">
                        <Button variant="danger" onClick={(e) => Cancelar(e)}>Cancelar</Button>
                    </div>
                </div>
            </Modal.Footer>

            {/* <Modal.Footer className='bg-dark text-light py-0 limit-height d-flex justify-content-center p-0'>
                <div className="container limit-width p-0">
                    <Button className="w-100" variant="danger" onClick={(e) => Cancelar(e)}>Cancelar</Button>
                </div> */}
                {/* <Button variant="success" onClick={() => btnSalvar()}><i className="fa fa-fw fa-check"></i> Salvar</Button> */}
            {/* </Modal.Footer> */}
        </Modal>                    
    </>
}

export default VendedoresLoc;