import React from 'react';
import Formata from './Formata';
import IfComponent from './IfComponent';
import { NumericFormat } from 'react-number-format';

function ProdutoHeader(props) {
    return <>
        <IfComponent condicional={props.PRO_CODIGO>0}>
        <div className="row">  
            <div className={props.imagens.length>0?"col-12 bg-success bg-opacity-10":"col-12 bg-success bg-opacity-10"}>
                <div className="d-flex align-items-stretch">      
                    <div><img data-fancybox="avatar" src={props.PRF_ARQUIVO} style={{maxWidth: '75px'}} alt={props.PRO_CODIGO} className="rounded-5 img-fluid me-2 p-0 border border-light border-3 shadow-sm"/></div>
                    <div className="row gx-2">
                        <div className="col-4 text-end mt-2">ID:</div>
                        <div className="col-8 mt-1"><span className="fw-bold fs-6 badge bg-success bg-opacity-75 rounded-pill"><Formata formato="00000000">{props.PRO_CODIGO}</Formata></span></div>
                        <div className="col-4 text-end">Estoque:</div><div className="col-8 fw-bold">
                            <NumericFormat 
                                value={parseFloat(props.PRO_ESTOQUEATUAL)}
                                // isNumericString={true}
                                displayType="text"
                                prefix=""
                                thousandsGroupStyle="thousand"
                                decimalSeparator=","
                                decimalScale={4}
                                fixedDecimalScale={true}
                                thousandSeparator="."
                                allowNegative={true}
                            /> {props.PRO_UNIDADE}
                        </div>
                        <div className="col-4 text-end">Valor:</div><div className="col-8 fw-bold text-danger">
                            <NumericFormat 
                                value={parseFloat(props.PRO_PRECOVENDA)}
                                // isNumericString={true}
                                displayType="text"
                                prefix="R$ "
                                thousandsGroupStyle="thousand"
                                decimalSeparator=","
                                decimalScale={2}
                                fixedDecimalScale={true}
                                thousandSeparator="."
                                allowNegative={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12">
                <p className="lead fw-bold text-center lh-1 my-3 m-0">{props.PRO_NOME}</p>
            </div>
        </div>    
        </IfComponent>
    </>
}

export default ProdutoHeader;