import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

// funcoes proprias
import Api from '../Api';
import { AuthContext } from '../contextos/Auth';
import ProdutoCard from '../componentes/ProdutoCard';
import IfComponent from '../componentes/IfComponent';
import Sentinela from '../componentes/Sentinela';

function Produtos() {
    const { logout } = useContext(AuthContext);
    const [ listagem, setListagem ] = useState([]);
    const [ carregando, setCarregando ] = useState(false);
    const [ pagina, setPagina ] = useState(0);
    const [ final, setFinal ] = useState(false);
    const [ pesquisa, setPesquisa ] = useState('');
    const [ oferta, setOferta ] = useState(2);
    const [ ofertaClass, setOfertaClass ] = useState('/icones/discount_branco.svg');
    
    const listarProdutos = useCallback((nPagina,bFinal,bLimpar) => {        
        if (!bFinal) {
            setCarregando(true);    
            const novaPagina=nPagina+1;
            //setPagina((paginaInsideState) => paginaInsideState+1);
                        
            var formData = new FormData();
                formData.set('op', 'listar');
                formData.set('pagina', novaPagina);
                formData.set('pesquisa', pesquisa);
                formData.set('oferta', oferta);
            
            Api.post('produtos.php',formData).then((response) => {
                if (response.data.erro==='N') {
                    const novaListagem = [];
                    if (response.data.contador > 0) {
                        if (!bLimpar){
                            //const novaListagem = [...listagem] //novalistagem recebe listagem atual.
                            novaListagem.push(...listagem); //novalistagem recebe listagem atual.
                            setListagem([]);
                        }
                        novaListagem.push(...response.data.msg); //novalistagem recebe mais a nova listagem.
                    } else {
                        //se ja tem uma listagem, continua com ela
                        //se a pesquisa retornou 20 registros, ao dar scroll para a pagina 2 que retorna com 0 registros, continua com a listagem atual.
                        if (novaPagina > 1) { 
                            novaListagem.push(...listagem); 
                        }
                    }

                    //verifica se pode conter mais registros. 
                    //se retornou com 20 registros, pode ser que ainda tenha mais.
                    //se retornou com menos de 20 registros, é pq chegou no final da lista.
                    if (response.data.contador < 20) {
                        setFinal(true);
                    }

                    setListagem(novaListagem);
                    setCarregando(false);
                    setPagina(novaPagina);
                } else {
                    logout();
                    response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
                }
            }).catch((response) => {
                setCarregando(false);
                console.log('CATCH',JSON.stringify(response.data));
            });
        }
    },[listagem,logout,oferta,pesquisa]);

    function carregarMais() {
        listarProdutos(pagina,final,false);
    }

    useEffect(() => {
        //inicialmente setamos o valor 2 para desejo para ele nao executar o listarVitrine no inicio.
        if (oferta !== 2) { 
            setPagina(0);
            setFinal(false);
            listarProdutos(0,false,true);        
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[oferta]); 

    function Pesquisar() {
        setPagina(0);
        setFinal(false);
        setListagem([]);      
        listarProdutos(0,false,true);
    }

    function onOfertaClick() {
        //setando "desejo", dispara o useEffect dele.
        if (oferta===0 || oferta===2) {
            setOferta(1);
            setOfertaClass('/icones/discount_verde.svg');
        } else {
            setOferta(0);
            setOfertaClass('/icones/discount_branco.svg');            
        }
    }

    const localizarKeyDown = (e) => {
        if (e.keyCode === 13) {
            Pesquisar();
        }
    }  

    return <>
        <div className="bg-dark fixed-top">
            <div className="container-fluid limit-width">
                <div className="row">
                    <div className="col-12 limit-height d-flex justify-content-between align-items-center">
                        <div className="link-icone"><Link to='/home'><i className="fa fa-2x fa-fw fa-chevron-left"></i></Link></div>
                        <div className="flex-grow-1"><p className="text-truncate text-center text-light fw-bolder mt-3">PRODUTOS</p></div>
                        <div className="link-icone"><Link to="" onClick={onOfertaClick}><img className="link-icone-svg" src={ofertaClass} alt=""/></Link></div>
                    </div>
                </div>
            </div>
            <div className="container-fluid limit-width">
                <div className="row">
                    <div className="col-12 limit-height d-flex justify-content-between align-items-center">
                        <div className="w-100">
                            <input className="form-control border-0 bg-light bg-opacity-10 rounded-pill text-light disable-form-control-focus" type="text" name="texto" value={pesquisa} placeholder="Código ou nome do produto" onChange={(e) => setPesquisa(e.target.value.toUpperCase())} onKeyDown={(e) => localizarKeyDown(e)}/>
                        </div>
                        <div className="ms-2"><button className="btn btn-success rounded-pill" onClick={() => { Pesquisar(); }} >Filtrar</button></div>
                        {/* <div className="ms-2"><button className="btn btn-success rounded-pill" onClick={() => { setFinal(false); setPagina(0); setListagem([]); findGeneros() }} >Filtrar</button><input type="hidden" name="op" value="localizar"/></div> */}
                    </div>
                </div>
            </div>
        </div>
        <IfComponent condicional={ listagem.length > 0}>
            {/* <div className="container limit-width pb-2"></div> */}
            <div className="container limit-width p-0 topspace-100">
                {                     
                    listagem.map(produto => (
                        <ProdutoCard 
                            key={produto.PRO_CODIGO}
                            GRU_DESCRICAO={produto.GRU_DESCRICAO}
                            PRF_ARQUIVO={produto.PRF_ARQUIVO}
                            PRO_CODIGO={produto.PRO_CODIGO}
                            PRO_ESTOQUEATUAL={produto.PRO_ESTOQUEATUAL}
                            PRO_NOME={produto.PRO_NOME}
                            PRO_OFERTA={produto.PRO_OFERTA}
                            PRO_UNIDADE={produto.PRO_UNIDADE}
                            PRO_PRECOVENDA={produto.PRO_PRECOVENDA}
                        />                   
                    ))
                }   
            </div>
        </IfComponent>  
        <Sentinela onIntersect={carregarMais} carregando={carregando} final={final} length={listagem.length} />
    </>
}

export default Produtos;