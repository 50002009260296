import React, { useEffect, useRef } from 'react';

import IfComponent from './IfComponent';
import { ReactComponent as IcoWait } from "../Imagens/wait.svg";
import { ReactComponent as IcoBoxImportant } from "../Imagens/box_important.svg";

function Sentinela({ onIntersect, carregando, final, length }) {
    // return (condicional ? children : null);
    // const { carregando, pagina, final, length, listar } = props;
    const sentinelaRef = useRef();
    // const [ scrollRatio, setScrollRatio ] = useState(null);

    useEffect(() => {
        // const currentSentinel = sentinelaRef.current;

        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    onIntersect();
                }
            },
            {
                root: null, // Observa a viewport
                rootMargin: '0px',
                threshold: 1.0 // Chama o callback quando 100% do sentinel está visível
            }
        );
    
        if (sentinelaRef.current) {
            // observer.observe(currentSentinel.current);
            observer.observe(sentinelaRef.current);
        }
    
        return () => {
            if (sentinelaRef.current) {
                // const tempRef = sentinelaRef;
                // observer.unobserve(currentSentinel.current);
                // eslint-disable-next-line react-hooks/exhaustive-deps
                observer.unobserve(sentinelaRef.current);
            }
        };
    }, [onIntersect]);
      
      
    // useEffect(() => {
    //     const intersectionObserver = new IntersectionObserver((entries) => {
    //         const ratio = entries[0].intersectionRatio;
    //         setScrollRatio(ratio);
    //     });
        
    //     intersectionObserver.observe(scrollObserver.current);
    //     return () => {
    //         intersectionObserver.disconnect();
    //     }
    // },[]);

    // //executa este bloco toda vez que o div sentinela aparece na tela.
    // useEffect(() => {      
    //     if (scrollRatio > 0) {
    //         listar(pagina,final,false); 
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // },[scrollRatio]);

    return <>
        <IfComponent condicional={ length !== 0 && final }>
            <div className="container limit-width">
                <p className="lead text-center">Fim da listagem!</p>
            </div>
        </IfComponent>
        <IfComponent condicional={ carregando && !final }>
        {/* <IfComponent condicional={ carregando }> */}
            <div className="container mb-5 text-center"><IcoWait className="ico-32 ico-animado"/>Carregando mais...</div>
        </IfComponent>
        <IfComponent condicional={ length === 0 && carregando === false }>
        {/* <IfComponent condicional={ length === 0 }> */}
            <div className="container limit-width topspace-50 d-flex justify-content-center align-items-center flex-column">
                <IcoBoxImportant className="ico-150"/>
                <p className="lead">Nenhum registro encontrado!</p>
                <span>Atualize ou revise os filtros de pesquisa utilizados.</span>
            </div>                    
        </IfComponent> 
        <div className="container limit-width bg-transparent pb-5 mb-5" ref={sentinelaRef} id="sentinela"></div>
    </>
}

export default Sentinela;