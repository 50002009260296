import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { AuthContext } from '../contextos/Auth';
import IfComponent from '../componentes/IfComponent';
import { ReactComponent as IcoUserGroup } from "../Imagens/user_group.svg";
import { ReactComponent as IcoBrick } from "../Imagens/brick.svg";
import { ReactComponent as IcoPaperMoney } from "../Imagens/paper_money.svg";
import { ReactComponent as IcoShoppingCart } from "../Imagens/shopping_cart.svg";
import { ReactComponent as IcoBarcodeScanner } from "../Imagens/barcode_scanner.svg";
import { ReactComponent as IcoDrill } from "../Imagens/drill.svg";
import { ReactComponent as IcoIncrease } from "../Imagens/increase.svg";
import { ReactComponent as Logo } from "../Imagens/logo_runtime_black.svg";
import CertificadoValidade from '../componentes/CertificadoValidade';


function Home () {

    const { logado, logout, usuarioNome, empresa, versaoBase } = useContext(AuthContext);
    
    return <>
        <IfComponent condicional={logado===true}>
            <div className="bg-dark" style={{height: '100vh'}}>
            <div className="container-fluid limit-width pt-5 text-center">
                <Logo style={{maxWidth: '280px'}} className="mb-2" />
                <div className="col-12 mb-2 text-white text-opacity-50">
                    <span className="fw-bold">{empresa}</span><br/>{usuarioNome}
                     {/* {process.env.REACT_APP_URL_API} */}
                </div>
                <ul className="list-group mb-2 text-start">
                    <li className="list-group-item d-flex justify-content-between align-items-center"><Link to='/clientes' className="link-preto stretched-link w-100"><IcoUserGroup className="ico-25 me-3"/> Clientes</Link></li>
                    <li className="list-group-item d-flex justify-content-between align-items-center"><Link to='/produtos' className="link-preto stretched-link w-100"><IcoBrick className="ico-25 me-3" /> Produtos</Link></li>
                    <li className="list-group-item d-flex justify-content-between align-items-center"><Link to='/receber' className='link-preto stretched-link w-100'><IcoPaperMoney className="ico-25 me-3" /> Contas a Receber</Link></li>
                    <li className="list-group-item d-flex justify-content-between align-items-center"><Link to='/vendas' className='link-preto stretched-link w-100'><IcoShoppingCart className="ico-25 me-3" /> Pedido de Vendas</Link></li>
                    <li className="list-group-item d-flex justify-content-between align-items-center"><Link to='/vendasconferencias' className='link-preto stretched-link w-100'><IcoBarcodeScanner className="ico-25 me-3" /> Conferência de Pedido de Vendas</Link></li>
                    <li className="list-group-item d-flex justify-content-between align-items-center"><Link to='/servicos' className='link-preto stretched-link w-100'><IcoDrill className="ico-25 me-3" /> Ordens de Serviço</Link></li>
                    <li className="list-group-item d-flex justify-content-between align-items-center"><Link to='/metas' className='link-preto stretched-link w-100'><IcoIncrease className="ico-25 me-3" /> Minhas Metas</Link></li>
                </ul>
                <div className="col-12 mb-5 text-white text-opacity-50">
                    {'Versão Base: ' + versaoBase + ' - Versão App: '+process.env.REACT_APP_APP_VER}
                    <CertificadoValidade/>
                </div>
                <div className="col-12">
                    <button className="btn btn-lg btn-danger w-100" onClick={logout}><i className="pi pi-fw pi-sign-out"></i> DESCONECTAR</button>
                </div>
            </div>
            </div>
        </IfComponent>
    </>
}

export default Home;