import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { NumericFormat } from 'react-number-format';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Button as ButtonP } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputMask } from 'primereact/inputmask';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { SwipeableList, SwipeableListItem, SwipeAction, TrailingActions, Type as ListType } from 'react-swipeable-list';
import 'react-swipeable-list/dist/styles.css';
import nl2br from 'nl2br';

// funcoes proprias.
import Api from '../Api';
import { AuthContext } from '../contextos/Auth';
import IfComponent from '../componentes/IfComponent';
import ServicosItens from '../componentes/ServicosItens';
import ProdutosLoc from '../componentes/ProdutosLoc';
import VendedoresLoc from '../componentes/VendedoresLoc';
import EquipamentosLoc from '../componentes/EquipamentosLoc';

function Servico(props) {
    const { logout } = useContext(AuthContext);
    const { id } = useParams();
    const navigate = useNavigate();
    const [ servico, setServico ] = useState({});
    const [ servicoModal, setServicoModal ] = useState({SER_DTPREVISAO: '12/12/1212'});
    // const [ servicoV, setServicoV ] = useState({SER_DTPREVISAO: '12/12/1215'});
    const [ itens, setItens ] = useState([]);
    const [ servicosItens, setServicosItens ] = useState({}); //usado no modal.
    const [ showModal, setShowModal ] = useState(false); //para inserir item.
    const [ showModalServico, setShowModalServico ] = useState(false); //para inserir item.
    const [ tituloModal, setTituloModal ] = useState('INSERIR ITEM'); //usado no modal.
    const [ opModal, setOpModal ] = useState('inseriritem'); //usado no modal.

    const [ showEquipamentosLoc, setShowEquipamentosLoc ] = useState(false);
    const [ showProdutosLoc, setShowProdutosLoc ] = useState(false);
    const [ showVendedoresLoc, setShowVendedoresLoc ] = useState(false);
    const selectAndamento = [
        // {value: "TODOS", label: "TODOS" },
        {value: "AGUARDANDO APROVACAO", label: "AGUARDANDO APROVACAO" },
        {value: "AGUARDANDO INICIO", label: "AGUARDANDO INICIO" },
        {value: "AGUARDANDO RETIRADA", label: "AGUARDANDO RETIRADA" },
        {value: "EM TRABALHO", label: "EM TRABALHO" },
        {value: "FINALIZADA", label: "FINALIZADA" },
        {value: "PAUSADA", label: "PAUSADA" },
    ];

    async function VerServico() {
        var formData = new FormData();
        formData.set('op', 'ver');
        formData.set('servico', id);

        try {
            const response = await Api.post('servicos.php',formData);
            if (response.data.erro==='N') {
                setServico(response.data.msg);
                setItens(response.data.msg.ITENS);
            } else {
                setServico({SER_CODIGO:0});
                setItens({});
                response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
            }
        } catch (error) {
            console.log('CATCH',error);
        }
    }   
   
    useEffect(() => {
        VerServico();
        // eslint-disable-next-line
    },[]);

    async function btnSalvarServico() {
        const formData = new FormData();
        formData.set('op','alterarservico');
        formData.set('SER_ACESSORIOS',servicoModal.SER_ACESSORIOS || '');
        formData.set('SER_ANDAMENTO',servicoModal.SER_ANDAMENTO || '');
        formData.set('SER_CODIGO',servicoModal.SER_CODIGO || '');
        formData.set('SER_CONSERTO',servicoModal.SER_CONSERTO || '');
        formData.set('SER_DTPREVISAO',servicoModal.SER_DTPREVISAO || '');
        formData.set('SER_EQUIPAMENTO',servicoModal.SER_EQUIPAMENTO || '');
        formData.set('SER_KMATUAL',servicoModal.SER_KMATUAL || '');
        // formData.set('EQP_MARCA',servicoModal.SER_MARCA || '');
        // formData.set('SER_MODELO',servicoModal.SER_MODELO || '');
        // formData.set('SER_SERIE',servicoModal.SER_SERIE || '');
        formData.set('SER_DEFEITO',servicoModal.SER_DEFEITO || '');
        formData.set('SER_TECNICO',servicoModal.SER_TECNICO || '');
        const response = await Api.post('servicos.php',formData);
        if (response.data.erro==='N') {
            setServico(response.data.msg);
            setShowModalServico(false);
        }
        if (response.data.erro==='S') {
            response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
        }
    }

    async function btnSalvarItem() {
        const formData = new FormData();
        //formData.set('op','inseriritem');
        formData.set('op',opModal);
        formData.set('SEI_SERVICO',servico.SER_CODIGO || '');
        formData.set('SEI_PRODUTO',servicosItens.SEI_PRODUTO || '');
        formData.set('SEI_DESCRICAO',servicosItens.SEI_DESCRICAO || '');
        formData.set('SEI_QUANTIDADE',servicosItens.SEI_QUANTIDADE || '');
        formData.set('SEI_DESCPORC',servicosItens.SEI_DESCPORC || '');
        // formData.set('SEI_DESCVALOR',servicosItens.SEI_DESCVALOR || '');
        formData.set('SEI_COMPLEMENTO',servicosItens.SEI_COMPLEMENTO || '');
        const response = await Api.post('servicos.php',formData);
        if (response.data.erro==='N') {
            //Swal.fire({title: 'Yessss!', text: 'Item inserido com sucesso!', icon: 'success', showConfirmButton: false, timer: 1500});
            setServicosItens({});
            setServico(response.data.msg);
            setItens(response.data.msg.ITENS);
            setShowModal(false);
        } 
        if (response.data.erro==='S') {
            response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
        }
    }

    function setCampo(e,objeto,upper) {
        const { name, value } = e.target;
        let valor='';
        if (upper) {
            valor = value?value.toUpperCase():'';
        } else {
            valor = value;
        }
        if (objeto==="servicoModal") {
            setServicoModal({...servicoModal, [name]: valor});
        }
        if (objeto==="servicosItens") {
            setServicosItens({...servicosItens, [name]: valor});
        }
    }

    //funcoes do modal de produtos.
    function ProdutosLocClose(event, data) {
        setServicosItens({...servicosItens, 'SEI_PRODUTO': data.PRO_CODIGO, 'SEI_DESCRICAO': data.PRO_NOME, 'SEI_DESCMAX': data.PRO_DESCMAX, 'SEI_VALORUNIT': data.PRO_PRECOVENDA, 'SEI_QUANTIDADE': '1' })
        setShowProdutosLoc(false);
    }

    function ProdutosLocOpen(event) {
        setShowProdutosLoc(true);
    }

    //funcoes do modal de vendedores.
    function VendedoresLocClose(event, data) {
        setServico({...servico, 'SER_TECNICO': data.VEN_CODIGO, 'VEN_NOME': data.VEN_NOME })
        setShowVendedoresLoc(false);
    }

    function VendedoresLocOpen(event) {
        setShowVendedoresLoc(true);
    }

    //funcoes do modal de equipamentos.
    function equipamentosLocClose(event, data) {
        setServicoModal({...servico, 
            'SER_EQUIPAMENTO': data.EQP_CODIGO, 
            'EQP_ANOFAB': data.EQP_ANOFAB, 
            'EQP_ANOMOD': data.EQP_ANOMOD, 
            'EQP_CHASSI': data.EQP_CHASSI, 
            'EQP_COMBUSTIVEL': data.EQP_COMBUSTIVEL, 
            'EQP_COR': data.EQP_COR, 
            'EQP_DESCRICAO': data.EQP_DESCRICAO, 
            'EQP_MARCA': data.EQP_MARCA, 
            'EQP_MODELO': data.EQP_MODELO, 
            'EQP_SERIE': data.EQP_SERIE 
        });
        setShowEquipamentosLoc(false);
    }

    function equipamentosLocOpen(event) {
        setShowEquipamentosLoc(true);
    }

    //https://bestofreactjs.com/repo/marekrozmus-react-swipeable-list-react-sortable-list
    // const leadingActions = ({id}) => (
    //     <LeadingActions>
    //         <SwipeAction destructive={false} onClick={() => console.info(id, 'clique estrela')}>
    //             <div className="d-flex align-items-center bg-success text-light text-center p-3"><i className="fa fa-fw fa-star text-warning"></i> Estrela</div>
    //         </SwipeAction>     
    //     </LeadingActions>
    // );
    
    //para a exclusao do item
    const trailingActions = ({item}) => (
        <TrailingActions>
            <SwipeAction destructive={false} onClick={() => {
                Swal.fire({
                    position: 'center',
                    icon: 'question',
                    title: 'Confirma Exclusão?',
                    text: 'Tem certeza que deseja excluir '+item.SEI_DESCRICAO+'?',
                    showCancelButton: true,
                    confirmButtonText: 'Sim, quero!',
                    cancelButtonText: 'Nãããoooooo',
                    confirmButtonColor: '#212529'
                }).then((result) => {
                    if (result.isConfirmed) {
                        var bodyFormData = new FormData();
                        bodyFormData.set('op', 'excluiritem');
                        bodyFormData.set('SEI_CODIGO', item.SEI_CODIGO);
                        Api.post('servicos.php',bodyFormData).then((response) => {
                            if (response.data.erro==='N') {
                                //Swal.fire({ position: 'center', icon: 'success', title: response.data.msg, showConfirmButton: false, timer: 1500 });
                                setServico(response.data.msg);
                                setItens(response.data.msg.ITENS);
                            } else {
                                response.data.token==="expirado"?logout():Swal.fire({ title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529' });
                            }
                        }).catch(({response}) => {
                            console.log('CATCH',JSON.stringify(response));
                        });
                    }
                });
            }}>
                <div className="d-flex align-items-center bg-danger text-light text-center p-3" style={{cursor: 'pointer'}}><i className="fa fa-fw fa-trash"></i> Excluir</div>
            </SwipeAction>
        </TrailingActions>
    );

    //para abrir a insercao do item.
    function inserirItem(item) {
        setTituloModal('INSERIR ITEM');
        setOpModal('inseriritem');
        setServicosItens({});
        setShowModal(true);
    }

    function modalServico() {
        setShowModalServico(true);
        // setServicoModal({...servico, SER_DTPREVISAO: '02/02/2025' });
    }

    useEffect(() => {
        setServicoModal(servico);
    },[servico])

    return <>    
        <div className="bg-dark fixed-top">
            <div className="container-fluid limit-width p-0">
                <div className="row">
                    <div className="col-12 limit-height d-flex justify-content-between align-items-center">
                        <div className="">
                            <Link to="" onClick={() => navigate(-1)} className="link-icone"><i className="fa fa-2x fa-fw fa-chevron-left mx-2"></i></Link>
                        </div>
                        <div className="flex-grow-1 text-truncate"><p className="text-truncate fw-bold text-center text-light mt-3">ORDEM DE SERVIÇO</p></div>
                        <div className="">
                            {/* <div className="link-icone me-3">&nbsp;</div> */}
                            {
                                servico.SER_STATUS==='A'
                                    ? <Link to="" onClick={() => modalServico()} className="link-icone"><i className="fa fa-2x fa-fw fa-pencil mx-2"></i></Link>
                                    : <span>&nbsp;</span>
                            }                                    
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <IfComponent condicional={servico.SER_CODIGO<=0}>
            <div className="container limit-width vh-100 d-flex justify-content-center align-items-center">
                <p className="text-center lead"><span style={{fontSize: '7em'}} className="text-muted"><i className="fa fa-fw fa-record-vinyl"></i></span><br/>Não foi possível localizar a O.S.</p>
            </div>  
        </IfComponent>
        <IfComponent condicional={servico.SER_CODIGO>0}>
            <div className="container-fluid limit-width mt-5 pt-2">
                <ul className="list-group mb-3">  
                    <li className="list-group-item p-2">    
                        <div className="row">      
                            <div className="col-3">
                                <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">CÓDIGO</sup></p><p className="lh-1 m-0">{servico.SER_CODIGO}</p>
                            </div>      
                            <div className="col-3">
                                <IfComponent condicional={servico.SER_STATUS==='A'}>
                                    <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">STATUS</sup></p><p className="lh-1 m-0 fw-bold text-primary">ABERTO</p>
                                </IfComponent>
                                <IfComponent condicional={servico.SER_STATUS==='F'}>
                                    <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">STATUS</sup></p><p className="lh-1 m-0 fw-bold text-success">FINALIZADO</p>
                                </IfComponent>
                                <IfComponent condicional={servico.SER_STATUS==='B'}>
                                    <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">STATUS</sup></p><p className="lh-1 m-0 fw-bold text-danger">BAIXADO</p>
                                </IfComponent>
                            </div>
                            <div className="col-6">
                                <IfComponent condicional={servico.SER_ANDAMENTO==="AGUARDANDO APROVACAO"}>
                                    <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">ANDAMENTO</sup></p><p className="lh-1 m-0 fw-bold text-dark">{servico.SER_ANDAMENTO}</p>
                                </IfComponent>
                                <IfComponent condicional={servico.SER_ANDAMENTO==="AGUARDANDO INICIO"}>
                                    <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">ANDAMENTO</sup></p><p className="lh-1 m-0 fw-bold text-primary">{servico.SER_ANDAMENTO}</p>
                                </IfComponent>
                                <IfComponent condicional={servico.SER_ANDAMENTO==="AGUARDANDO RETIRADA"}>
                                    <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">ANDAMENTO</sup></p><p className="lh-1 m-0 fw-bold text-info">{servico.SER_ANDAMENTO}</p>
                                </IfComponent>
                                <IfComponent condicional={servico.SER_ANDAMENTO==="EM TRABALHO"}>
                                    <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">ANDAMENTO</sup></p><p className="lh-1 m-0 fw-bold text-warning">{servico.SER_ANDAMENTO}</p>
                                </IfComponent>
                                <IfComponent condicional={servico.SER_ANDAMENTO==="FINALIZADA"}>
                                    <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">ANDAMENTO</sup></p><p className="lh-1 m-0 fw-bold text-success">{servico.SER_ANDAMENTO}</p>
                                </IfComponent>
                                <IfComponent condicional={servico.SER_ANDAMENTO==="PAUSADA"}>
                                    <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">ANDAMENTO</sup></p><p className="lh-1 m-0 fw-bold text-danger">{servico.SER_ANDAMENTO}</p>
                                </IfComponent>
                            </div>
                        </div>  
                    </li> 
                    <li className="list-group-item p-2"><p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">CLIENTE</sup></p><p className="lh-1 m-0">{servico.SER_CLIENTE+' - '+servico.CLI_NOME}</p></li>  
                    <li className="list-group-item p-2"><p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">TÉCNICO</sup></p><p className="lh-1 m-0">{servico.SER_TECNICO+' - '+servico.VEN_NOME}</p></li>  
                    <li className="list-group-item p-2"><p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">EQUIPAMENTO</sup></p><p className="lh-1 m-0">{servico.SER_EQUIPAMENTO+' - '+servico.EQP_DESCRICAO}</p></li>  
                    <li className="list-group-item p-2">    
                        <div className="row">      
                            <div className="col-6">
                                <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">MARCA</sup></p><p className="lh-1 m-0">{servico.EQP_MARCA}</p>
                            </div>      
                            <div className="col-6">
                                <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">MODELO</sup></p><p className="lh-1 m-0">{servico.EQP_MODELO}</p>
                            </div>    
                        </div>  
                    </li> 
                    <li className="list-group-item p-2">    
                        <div className="row">      
                            <div className="col-6">
                                <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">SERIE / PLACA</sup></p><p className="lh-1 m-0">{servico.EQP_SERIE}</p>
                            </div>      
                            <div className="col-6">
                                <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">ACESSÓRIOS</sup></p><p className="lh-1 m-0">{servico.SER_ACESSORIOS}</p>
                            </div>    
                        </div>  
                    </li> 
                    <li className="list-group-item p-2">    
                        <div className="row">      
                            <div className="col-6">
                                <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">DATA ENTRADA</sup></p><p className="lh-1 m-0">{servico.SER_DTENTRADA}</p>
                            </div>      
                            <div className="col-6">
                                <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">DATA PREVISÃO</sup></p><p className="lh-1 m-0">{servico.SER_DTPREVISAO}</p>
                            </div>    
                        </div>  
                    </li> 
                    <li className="list-group-item p-2">    
                        <div className="row">      
                            <div className="col-6">
                                <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">DESCONTO</sup></p>
                                <p className="lh-1 m-0">
                                    <NumericFormat 
                                        value={parseFloat(servico.SER_DESCVALOR)}
                                        // isNumericString={true}
                                        displayType="text"
                                        prefix="R$ "
                                        thousandsGroupStyle="thousand"
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator="."
                                        allowNegative={true}
                                    />
                                </p>
                            </div>      
                            <div className="col-6">
                                <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">VALOR TOTAL (TABELA {servico.SER_TABELAPRECO})</sup></p>
                                <p className="lh-1 m-0 text-danger">
                                    <NumericFormat 
                                        value={parseFloat(servico.SER_VALORTOTAL)}
                                        // isNumericString={true}
                                        displayType="text"
                                        prefix="R$ "
                                        thousandsGroupStyle="thousand"
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator="."
                                        allowNegative={true}
                                    />
                                </p>
                            </div>    
                        </div>  
                    </li> 
                    <li className="list-group-item p-2"><p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">DEFEITO ALEGADO</sup></p><span className="lh-1 m-0"><section dangerouslySetInnerHTML={{ __html: nl2br(servico.SER_DEFEITO) || '-' }} /></span></li>
                    <li className="list-group-item p-2"><p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">CONSERTO EFETUADO</sup></p><span className="lh-1 m-0"><section dangerouslySetInnerHTML={{ __html: nl2br(servico.SER_CONSERTO) || '-' }} /></span></li>
                </ul>
            </div>
        </IfComponent>

        <div className="container-fluid limit-width">
            <div className="row">
                <div className="col-12 d-flex justify-content-between align-items-center bg-secondary bg-opacity-25 limit-height">
                    <div className="flex-grow-1"><p className="text-truncate text-black fs-3 fw-bolder mt-3">Itens</p></div>
                    <div className="link-icone">
                        {
                            servico.SER_STATUS==='A'
                                ? <Link to="" className="text-black" onClick={() => inserirItem()}><i className="fa fa-2x fa-fw fa-plus"></i></Link>
                                : <span>&nbsp;</span>
                        }
                    </div>
                </div>
            </div>
        </div>

        <IfComponent condicional={itens.length > 0}>
            <div className="container-fluid limit-width p-0 pb-2">
                <div className="mb-5">
                    <SwipeableList fullSwipe={false} type={ListType.IOS}>
                        {                    
                            itens.map(item => (
                                <SwipeableListItem key={item.SEI_CODIGO} trailingActions={trailingActions({ item })} blockSwipe={servico.SER_STATUS==='A'?false:true}>
                                    <ServicosItens
                                        key={item.SEI_CODIGO}
                                        SEI_PRODUTO={item.SEI_PRODUTO}
                                        SEI_DESCRICAO={item.SEI_DESCRICAO}
                                        SEI_QUANTIDADE={item.SEI_QUANTIDADE}
                                        SEI_SEQUENCIA={item.SEI_SEQUENCIA}
                                        SEI_UNIDADE={item.SEI_UNIDADE}
                                        SEI_VALORTOTAL={item.SEI_VALORTOTAL}
                                        SEI_VALORUNIT={item.SEI_VALORUNIT}
                                    />
                                </SwipeableListItem>
                            ))
                        }
                    </SwipeableList>
                </div>             
            </div>             
        </IfComponent>

        <Modal show={showModalServico} fullscreen={true} backdrop="static" className="">
            <Modal.Header className="bg-dark text-light p-0 limit-height">
                <div className="container limit-width d-flex justify-content-between align-items-center">
                    <Modal.Title><h5 className="modal-title">EDITAR O.S.</h5></Modal.Title>
                </div>
            </Modal.Header>

            <Modal.Body className="p-0">
                <div className="container limit-width mt-2">
                    <div className="row gx-1 p-inputtext-sm pett-input">

                        <div className="col-12 mb-1">
                            <div className="p-inputgroup">
                                <span className="p-float-label pett-label w-25">
                                    <InputText className="" name="SER_TECNICO" value={servicoModal.SER_TECNICO || ''} readOnly/>
                                    {/* <InputNumber className="inputnumber-pett" name="SER_TECNICO" value={servicoModal.SER_TECNICO || ''} onValueChange={(e) => setCampo(e,'servicoModal',false)} useGrouping={false} onBlur={(e) => tecnicoLocalizar(e)}/> */}
                                    <label htmlFor="SER_TECNICO">TÉCNICO</label>
                                </span>
                                <InputText className="w-75" name="VEN_NOME" value={servicoModal.VEN_NOME || ''} readOnly/>
                                <ButtonP className="px-3 p-button-success" icon="pi pi-search" onClick={(e) => VendedoresLocOpen(e)}/>
                            </div>          
                        </div>

                        <div className="col-12 mb-1">
                            {/* <span className="p-float-label pett-label">
                                <InputText className="w-100" name="SER_PRODUTO" value={servicoModal.SER_PRODUTO || ''} onChange={(e) => setCampo2(e)} />
                                <label htmlFor="SER_PRODUTO">PRODUTO</label>
                            </span> */}
                            <div className="p-inputgroup">
                                <span className="p-float-label pett-label w-25">
                                    <InputText name="SER_EQUIPAMENTO" value={servicoModal.SER_EQUIPAMENTO || ''} readOnly/>
                                    <label htmlFor="SER_EQUIPAMENTO">EQUIPAMENTO</label>
                                </span>
                                <InputText className="w-75" name="EQP_DESCRICAO" value={servicoModal.EQP_DESCRICAO || ''} readOnly/>
                                <ButtonP className="px-3 p-button-success" icon="pi pi-search" onClick={(e) => equipamentosLocOpen(e)}/>
                            </div>          
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="EQP_MARCA" value={servicoModal.EQP_MARCA || ''} readOnly disabled/>
                                <label htmlFor="EQP_MARCA">MARCA</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="EQP_MODELO" value={servicoModal.EQP_MODELO || ''} readOnly disabled/>
                                <label htmlFor="EQP_MODELO">MODELO</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="EQP_SERIE" value={servicoModal.EQP_SERIE || ''} readOnly disabled/>
                                <label htmlFor="EQP_SERIE">SÉRIE / PLACA</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="EQP_CHASSI" value={servicoModal.EQP_CHASSI || ''} readOnly disabled/>
                                <label htmlFor="EQP_CHASSI">CHASSI</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="EQP_COMBUSTIVEL" value={servicoModal.EQP_COMBUSTIVEL || ''} readOnly disabled/>
                                <label htmlFor="EQP_COMBUSTIVEL">COMBUSTIVEL</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="EQP_COR" value={servicoModal.EQP_COR || ''} readOnly disabled/>
                                <label htmlFor="EQP_COR">COR</label>
                            </span>
                        </div>
                        <div className="col-12 mb-1">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="SER_ACESSORIOS" value={servicoModal.SER_ACESSORIOS || ''} onChange={(e) => setCampo(e,'servicoModal',true)} />
                                <label htmlFor="SER_ACESSORIOS">ACESSÓRIOS</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputNumber className="w-100" name="SER_KMATUAL" value={servicoModal.SER_KMATUAL || ''} onValueChange={(e) => setCampo(e,'servicoModal',false)} useGrouping={false}/>
                                <label htmlFor="SER_KMATUAL">KM ATUAL</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                {/* <InputMask className="w-100" id="SER_DTPREVISAO" name="SER_DTPREVISAO" mask="99/99/9999" value={"28/02/2025"} onChange={(e) => setCampo(e,'servicoModal',false)} /> */}
                                {servicoModal.SER_DTPREVISAO && (
                                <InputMask className="w-100" name="SER_DTPREVISAO" mask="99/99/9999" value={servicoModal.SER_DTPREVISAO} onChange={(e) => setCampo(e,'servicoModal',false)} />
                                )}
                                <label htmlFor="SER_DTPREVISAO">DATA PREVISÃO</label>
                            </span>
                        </div>
                        <div className="col-12">
                            <span className="p-float-label pett-label mb-1">
                                <Dropdown className="w-100" name="SER_ANDAMENTO" value={servicoModal.SER_ANDAMENTO || ''} options={selectAndamento} onChange={(e) => setCampo(e,'servicoModal',false)}/>
                                <label htmlFor="SER_ANDAMENTO">ANDAMENTO</label>
                            </span>
                        </div>
                        <div className="col-12 mb-1">
                            <span className="p-float-label pett-label">
                                <InputTextarea className="w-100" name="SER_DEFEITO" value={servicoModal.SER_DEFEITO || ''} onChange={(e) => setCampo(e,'servicoModal',false)} autoResize/>
                                <label htmlFor="SER_DEFEITO">DEFEITO ALEGADO</label>
                            </span>
                        </div>

                        <div className="col-12 mb-1">
                            <span className="p-float-label pett-label">
                                <InputTextarea className="w-100" name="SER_CONSERTO" value={servicoModal.SER_CONSERTO || ''} onChange={(e) => setCampo(e,'servicoModal',false)} autoResize/>
                                <label htmlFor="SER_CONSERTO">CONSERTO EFETUADO</label>
                            </span>
                        </div>
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer className='bg-dark text-light p-0 limit-height d-flex justify-content-center'>
                <div className="container limit-width btn-group p-0">
                    <Button variant="danger" onClick={() => setShowModalServico(false)}>Cancelar</Button>
                    <Button variant="success" onClick={() => btnSalvarServico()}><i className="fa fa-fw fa-check"></i> Salvar</Button>
                </div>
            </Modal.Footer>
        </Modal>

        <Modal show={showModal} fullscreen={true} backdrop="static">
            <Modal.Header className="bg-dark text-light p-0 limit-height">
                <div className="container limit-width">
                    <Modal.Title><h5 className="modal-title">{tituloModal}</h5></Modal.Title>
                </div>
            </Modal.Header>

            <Modal.Body className="p-0">
                <div className="container limit-width mt-2">
                    <div className="row g-0">
                        <div className="col-12 mb-1 p-inputtext-sm pett-input">
                            <div className="p-inputgroup">
                                <span className="p-float-label pett-label w-25">
                                    <InputText className="" name="SEI_PRODUTO" value={servicosItens.SEI_PRODUTO || ''} readOnly/>
                                    <label htmlFor="SEI_PRODUTO">PRODUTO</label>
                                </span>
                                <InputText className="w-75" name="SEI_DESCRICAO" placeholder="Produto" value={servicosItens.SEI_DESCRICAO || ''} readOnly/>
                                <ButtonP className="px-3 p-button-success" icon="pi pi-search" onClick={(e) => ProdutosLocOpen(e)}/>
                            </div>          
                        </div>

                        <div className="col-12 mb-1 p-inputtext-sm pett-input">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="SEI_COMPLEMENTO" value={servicosItens.SEI_COMPLEMENTO || ''} onChange={(e) => setCampo(e,'servicosItens',true)} />
                                <label htmlFor="SEI_COMPLEMENTO">COMPLEMENTO</label>
                            </span>
                        </div>

                        <div className="col-12 mb-1 p-inputtext-sm pett-input">
                            <span className="p-float-label pett-label">
                                <InputNumber className="w-100 p-inputwrapper-filled" name="SEI_QUANTIDADE" value={servicosItens.SEI_QUANTIDADE || ''} onValueChange={(e) => setCampo(e,'servicosItens',false)} mode="decimal" locale='pt-BR'/>
                                <label htmlFor="SEI_QUANTIDADE">QUANTIDADE</label>
                            </span>
                        </div>

                        <div className="col-12 mb-1 p-inputtext-sm pett-input">
                            <span className="p-float-label pett-label">
                                <InputNumber className="w-100 p-inputwrapper-filled" name="SEI_VALORUNIT" value={servicosItens.SEI_VALORUNIT || ''} onValueChange={(e) => setCampo(e,'servicosItens',false)} mode="currency" currency="BRL" locale="pt-BR" disabled readOnly/>
                                <label htmlFor="SEI_VALORUNIT">VALOR UNITÁRIO</label>
                            </span>
                        </div>

                        <div className="col-12 mb-1 p-inputtext-sm pett-input">
                            <span className="p-float-label pett-label">
                                <InputNumber className="w-100 p-inputwrapper-filled" name="SEI_DESCMAX" value={servicosItens.SEI_DESCMAX || ''} onValueChange={(e) => setCampo(e,'servicosItens',false)} mode="decimal" locale="pt-BR" disabled readOnly/>
                                <label htmlFor="SEI_DESCMAX">DESCONTO MÁXIMO</label>
                            </span>
                        </div>
                        
                        <div className="col-12 mb-1 p-inputtext-sm pett-input">
                            <span className="p-float-label pett-label">
                                <InputNumber className="w-100 p-inputwrapper-filled" name="SEI_DESCPORC" value={servicosItens.SEI_DESCPORC || ''} onValueChange={(e) => setCampo(e,'servicosItens',false)} mode="decimal" locale="pt-BR" min={0} max={servicosItens.SEI_DESCMAX}/>
                                <label htmlFor="SEI_DESCPORC">% DESCONTO</label>
                            </span>
                        </div>

                        {/* <div className="col-12 mb-1 p-inputtext-sm pett-input">
                            <span className="p-float-label pett-label">
                                <InputNumber className="w-100 p-inputwrapper-filled" name="SEI_DESCVALOR" value={servicosItens.SEI_DESCVALOR || ''} onValueChange={(e) => setCampoValor(e)} mode="currency" currency="BRL" locale="pt-BR"/>
                                <label htmlFor="SEI_DESCVALOR">VALOR DESCONTO</label>
                            </span>
                        </div> */}

                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer className='bg-dark limit-height p-0 d-flex justify-content-center'>
                <div className="container limit-width p-0">
                    <div className="btn-group w-100">
                        <Button variant="danger" onClick={() => setShowModal(false)}>Cancelar</Button>
                        <Button variant="success" onClick={() => btnSalvarItem()}><i className="fa fa-fw fa-check"></i> Salvar</Button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>

        <EquipamentosLoc showModal={showEquipamentosLoc} onCloseModal={equipamentosLocClose}/>
        <ProdutosLoc showModal={showProdutosLoc} onCloseModal={ProdutosLocClose}/>
        <VendedoresLoc showModal={showVendedoresLoc} onCloseModal={VendedoresLocClose}/>
    </>
}

export default Servico;