import React, { useContext, useEffect, useState } from "react"
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Button as ButtonP } from 'primereact/button';
import { InputMask } from 'primereact/inputmask';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import Swal from 'sweetalert2';

import { AuthContext } from '../contextos/Auth';
import Api from "../Api";
import ConsultarPlaca from "./ConsultarPlaca/ConsultarPlaca";
import ClientesLoc from "./ClientesLoc";

function EquipamentosForm({show,registro,fechar,salvar}) {
    const { logout } = useContext(AuthContext);
    const [ showModalPlaca, setShowModalPlaca ] = useState(false);
    const [ showClientesLoc, setShowClientesLoc ] = useState(false);
    const [ equipamento, setEquipamento ] = useState({ 
        EQP_ACESSORIOS: '',
        EQP_ANOFAB: null,
        EQP_ANOMOD: null,
        EQP_CHASSI: '',
        EQP_CLIENTE: '',
        EQP_CODIGO: '',
        EQP_COMBUSTIVEL: '',
        EQP_COR: '',
        EQP_DESCRICAO: '',
        EQP_DTFILTRO: null,
        EQP_DTGERAL: '',
        EQP_DTLIMPEZA: '',
        EQP_LOCALIZACAO: '',
        EQP_MARCA: '',
        EQP_MODELO: '',
        EQP_REVENDEDOR: '',
        EQP_SERIE: null,
        EQP_STATUS: 'A',
        EQP_TAMANHO: '',
        CLI_NOME: '',
    });

    const selectStatus = [
        {value: "A", label: "ATIVO" },
        {value: "I", label: "INATIVO" }
    ];

    async function salvarEquipamento() {
        const formData = new FormData();
        formData.set('op','inserir');
        formData.set('EQP_ACESSORIOS',equipamento.EQP_ACESSORIOS || '');
        formData.set('EQP_ANOFAB',equipamento.EQP_ANOFAB || '');
        formData.set('EQP_ANOMOD',equipamento.EQP_ANOMOD || '');
        formData.set('EQP_CHASSI',equipamento.EQP_CHASSI || '');
        formData.set('EQP_CLIENTE',equipamento.EQP_CLIENTE);
        formData.set('EQP_CODIGO',equipamento.EQP_CODIGO);
        formData.set('EQP_COMBUSTIVEL',equipamento.EQP_COMBUSTIVEL || '');
        formData.set('EQP_COR',equipamento.EQP_COR || '');
        formData.set('EQP_DESCRICAO',equipamento.EQP_DESCRICAO || '');
        formData.set('EQP_DTFILTRO',equipamento.EQP_DTFILTRO || '');
        formData.set('EQP_DTGERAL',equipamento.EQP_DTGERAL || '');
        formData.set('EQP_DTLIMPEZA',equipamento.EQP_DTLIMPEZA || '');
        formData.set('EQP_LOCALIZACAO',equipamento.EQP_LOCALIZACAO || '');
        formData.set('EQP_MARCA',equipamento.EQP_MARCA || '');
        formData.set('EQP_MODELO',equipamento.EQP_MODELO || '');
        formData.set('EQP_REVENDEDOR',equipamento.EQP_REVENDEDOR || '');
        formData.set('EQP_SERIE',equipamento.EQP_SERIE || '');
        formData.set('EQP_STATUS',equipamento.EQP_STATUS || '');
        formData.set('EQP_TAMANHO',equipamento.EQP_TAMANHO || '');
        try {
            const response = await Api.post('equipamentos.php',formData);
            if (response.data.erro==='N') {
                Swal.fire({title: 'Yessss!', text: 'Equipamento cadastrado com sucesso!', icon: 'success', showConfirmButton: false, timer: 1500});
                setEquipamento({ 
                    EQP_ACESSORIOS: '',
                    EQP_ANOFAB: null,
                    EQP_ANOMOD: null,
                    EQP_CHASSI: '',
                    EQP_CLIENTE: '',
                    EQP_CODIGO: '',
                    EQP_COMBUSTIVEL: '',
                    EQP_COR: '',
                    EQP_DESCRICAO: '',
                    EQP_DTFILTRO: '',
                    EQP_DTGERAL: '',
                    EQP_DTLIMPEZA: '',
                    EQP_LOCALIZACAO: '',
                    EQP_MARCA: '',
                    EQP_MODELO: '',
                    EQP_REVENDEDOR: '',
                    EQP_SERIE: null,
                    EQP_STATUS: 'A',
                    EQP_TAMANHO: '',
                    CLI_NOME: '',
                });
                salvar(response.data.msg); //devolve o registro para a tela que chamou esse form.
                fechar();
            } 
            if (response.data.erro==='S') {
                response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
            }
        } catch(error) {
            console.log('CATCH',error);
        }
    }    

    function setCampo(e,objeto,upper) {
        const { name, value } = e.target;
        let valor='';
        if (upper) {
            valor = value?value.toUpperCase():'';
        } else {
            valor = value;
        }
        if (objeto==="equipamento") {
            setEquipamento({...equipamento, [name]: valor});
        }
    }

    function consultarPlacaClose() {
        setShowModalPlaca(false);
    }

    async function consultarPlacaSalvar(dados) {
        // const formData = new FormData();
        // formData.set('op','inserir');
        // formData.set()
        setEquipamento({
            ...equipamento, 
            EQP_ANOFAB: dados.ano,
            EQP_ANOMOD: dados.ano_modelo,
            EQP_CHASSI: dados.extra.chassi,
            EQP_COMBUSTIVEL: dados.combustivel.toUpperCase(), 
            EQP_COR: dados.cor.toUpperCase(), 
            EQP_DESCRICAO: dados.MODELO.toUpperCase(),
            EQP_MARCA: dados.MARCA.toUpperCase(), 
            EQP_SERIE: dados.placa_consultada.toUpperCase(),
            EQP_MODELO: dados.MODELO.toUpperCase(),
        });
        setShowModalPlaca(false);
    }

    useEffect(() => {
        if (registro.SER_CLIENTE) {
            setEquipamento((prevEquipamento) => {
                return {...prevEquipamento, EQP_CLIENTE: registro.SER_CLIENTE, CLI_NOME: registro.CLI_NOME}
            });
        }
    },[registro])

    //funcoes do modal de clientes.
    function clientesLocClose(event, data) {
        setEquipamento({...equipamento, 'EQP_CLIENTE': data.CLI_CODIGO, 'CLI_NOME': data.CLI_NOME })
        setShowClientesLoc(false);
    }

    function clientesLocOpen(event) {
        setShowClientesLoc(true);
    }
    
    // function equipamentosFormSave(equipamentoAtualizado) {
    //     setServico({
    //         ...servico, 
    //         SER_EQUIPAMENTO: equipamentoAtualizado.EQP_CODIGO, 
    //         EQP_CHASSI: equipamentoAtualizado.EQP_CHASSI,
    //         EQP_COMBUSTIVEL: equipamentoAtualizado.EQP_COMBUSTIVEL,
    //         EQP_COR: equipamentoAtualizado.EQP_COR,
    //         EQP_DESCRICAO: equipamentoAtualizado.EQP_DESCRICAO,
    //         EQP_MARCA: equipamentoAtualizado.EQP_MARCA,
    //         EQP_MODELO: equipamentoAtualizado.EQP_MODELO,
    //         EQP_SERIE: equipamentoAtualizado.EQP_SERIE,
    //     });
    // }
    function cancelar() {
        setEquipamento({
            EQP_ACESSORIOS: '',
            EQP_ANOFAB: null,
            EQP_ANOMOD: null,
            EQP_CHASSI: '',
            EQP_CLIENTE: '',
            EQP_CODIGO: '',
            EQP_COMBUSTIVEL: '',
            EQP_COR: '',
            EQP_DESCRICAO: '',
            EQP_DTFILTRO: null,
            EQP_DTGERAL: null,
            EQP_DTLIMPEZA: '',
            EQP_LOCALIZACAO: '',
            EQP_MARCA: '',
            EQP_MODELO: '',
            EQP_REVENDEDOR: '',
            EQP_SERIE: null,
            EQP_STATUS: 'A',
            EQP_TAMANHO: '',
            CLI_NOME: '',
        });
        fechar(); //funcao passada no props.
    }

    return <>
        <Modal show={show} fullscreen={true} backdrop="static">
            <Modal.Header className="bg-dark text-light p-0 limit-height">
                <div className="container limit-width">
                    <Modal.Title><h5 className="modal-title">NOVO EQUIPAMENTO</h5></Modal.Title>
                </div>
            </Modal.Header>

            <Modal.Body className="p-0">
                <div className="container limit-width">
                    <div className="row mb-0 mt-0 gx-1 p-inputtext-sm pett-input mt-2">
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="EQP_CODIGO" value={equipamento.EQP_CODIGO || ''} onChange={(e) => setCampo(e,'equipamento',false)} disabled readOnly/>
                                <label htmlFor="EQP_CODIGO">CÓDIGO</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <Dropdown className="w-100" name="EQP_STATUS" value={equipamento.EQP_STATUS || ''} options={selectStatus} onChange={(e) => setCampo(e,'equipamento',true)} placeholder="Selecione"/>
                                <label htmlFor="EQP_STATUS">STATUS</label>
                            </span>
                        </div>
                        <div className="col-12 mb-1">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="EQP_DESCRICAO" value={equipamento.EQP_DESCRICAO || ''} onChange={(e) => setCampo(e,'equipamento',true)} />
                                <label htmlFor="EQP_DESCRICAO">DESCRIÇÃO</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputMask className="w-100" mask="9999" name="EQP_ANOFAB" value={equipamento.EQP_ANOFAB} onChange={(e) => setCampo(e,'equipamentro',false)} />
                                <label htmlFor="EQP_ANOFAB">ANO FAB.</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputMask className="w-100" mask="9999" name="EQP_ANOMOD" value={equipamento.EQP_ANOMOD} onChange={(e) => setCampo(e,'equipamento',false)} />
                                <label htmlFor="EQP_ANOMOD">ANO MOD.</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputMask className="w-100" name="EQP_SERIE" mask={process.env.REACT_APP_MASCARA_PLACA} value={equipamento.EQP_SERIE} onChange={(e) => setCampo(e,'equipamento',false)} />
                                <label htmlFor="EQP_SERIE">SÉRIE / PLACA</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="EQP_MARCA" value={equipamento.EQP_MARCA || ''} onChange={(e) => setCampo(e,'equipamento',true)}/>
                                <label className="" htmlFor="EQP_MARCA">MARCA</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="EQP_MODELO" value={equipamento.EQP_MODELO || ''} onChange={(e) => setCampo(e,'equipamento',true)} />
                                <label htmlFor="EQP_MODELO">MODELO</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="EQP_COMBUSTIVEL" value={equipamento.EQP_COMBUSTIVEL || ''} onChange={(e) => setCampo(e,'equipamento',true)} />
                                <label htmlFor="EQP_COMBUSTIVEL">COMBUSTÍVEL</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="EQP_COR" value={equipamento.EQP_COR || ''} onChange={(e) => setCampo(e,'equipamento',true)} />
                                <label htmlFor="EQP_COR">COR</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="EQP_CHASSI" value={equipamento.EQP_CHASSI || ''} onChange={(e) => setCampo(e,'equipamento',true)} />
                                <label htmlFor="EQP_CHASSI">CHASSI</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputMask className="w-100" name="EQP_DTGERAL" mask="99/99/9999"  value={equipamento.EQP_DTGERAL || ''} onChange={(e) => setCampo(e,'equipamento',false)} />
                                <label htmlFor="EQP_DTGERAL">DT. GERAL</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputMask className="w-100" name="EQP_DTFILTRO" mask="99/99/9999"  value={equipamento.EQP_DTFILTRO || ''} onChange={(e) => setCampo(e,'equipamento',false)} />
                                <label htmlFor="EQP_DTFILTRO">DT. FILTRO</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputMask className="w-100" name="EQP_DTLIMPEZA" mask="99/99/9999"  value={equipamento.EQP_DTLIMPEZA || ''} onChange={(e) => setCampo(e,'equipamento',false)} />
                                <label htmlFor="EQP_DTLIMPEZA">DT. LIMPEZA</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="EQP_ACESSORIOS" value={equipamento.EQP_ACESSORIOS || ''} onChange={(e) => setCampo(e,'equipamento',true)} />
                                <label htmlFor="EQP_ACESSORIOS">ACESSÓRIOS</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="EQP_LOCALIZACAO" value={equipamento.EQP_LOCALIZACAO || ''} onChange={(e) => setCampo(e,'equipamento',true)} />
                                <label htmlFor="EQP_LOCALIZACAO">LOCALIZAÇÃO</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="EQP_REVENDEDOR" value={equipamento.EQP_REVENDEDOR || ''} onChange={(e) => setCampo(e,'equipamento',true)} />
                                <label htmlFor="EQP_REVENDEDOR">REVENDEDOR</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="EQP_TAMANHO" value={equipamento.EQP_TAMANHO || ''} onChange={(e) => setCampo(e,'equipamento',true)} />
                                <label htmlFor="EQP_TAMANHO">TAMANHO</label>
                            </span>
                        </div>

                        <div className="col-12 mb-1">
                            <div className="p-inputgroup">
                                <span className="p-float-label pett-label w-25">
                                    <InputText name="EQP_CLIENTE" value={equipamento.EQP_CLIENTE} readOnly/>
                                    <label htmlFor="EQP_CLIENTE">CLIENTE</label>
                                </span>
                                <InputText className="w-75" name="CLI_NOME" value={equipamento.CLI_NOME} readOnly/>
                                <ButtonP className="px-3 p-button-success" icon="pi pi-search" onClick={(e) => clientesLocOpen(e)}/>
                            </div>          
                        </div>
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer className='bg-dark text-light p-0 limit-height d-flex justify-content-center'>
                <div className="container limit-width btn-group p-0">
                    <Button className="w-100" variant="info" onClick={() => setShowModalPlaca(true)}>Consultar Placa</Button>
                    <Button className="w-100" variant="danger" onClick={() => cancelar()}>Cancelar</Button>
                    <Button className="w-100" variant="success" onClick={() => salvarEquipamento()}><i className="fa fa-fw fa-check"></i> Salvar</Button>
                </div>
            </Modal.Footer>
        </Modal>
        <ConsultarPlaca show={showModalPlaca} fechar={consultarPlacaClose} salvar={consultarPlacaSalvar}/>
        <ClientesLoc showModal={showClientesLoc} onCloseModal={clientesLocClose}/>
    </>
}

export default EquipamentosForm;