import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { uniqueId } from 'lodash';
import filesize from 'filesize';
import Swal from 'sweetalert2';

import Upload from '../componentes/Upload/Upload';
import FileList from '../componentes/Upload/FileList';
//import BarraSuperior from '../componentes/BarraSuperior';
//import BarraInferior from '../componentes/BarraInferior';
import Api from "../Api";
import ProdutoHeader from '../componentes/ProdutoHeader';
import IfComponent from '../componentes/IfComponent';

//https://github.com/rocketseat-content/youtube-upload-nodejs-reactjs-frontend
function ProdutoFotosEnviar() {
    const [ uploadedFiles, setUploadedFiles ] = useState(() => []);
    const { id } = useParams();
    const navigate = useNavigate();
    const [ produto, setProduto ] = useState({});
    const [ imagens, setImagens ] = useState([]);

    useEffect(() => {       
        // const response = await Api.get('discosfotos_upload.php');
        // this.setState({
        //     uploadedFiles: response.data.map(file => ({
        //         id: file._id,
        //         name: file.name,
        //         readableSize: filesize(file.size),
        //         preview: file.url,
        //         uploaded: true,
        //         url: file.url
        //     }))
        // });
    },[]);
    
    useEffect(() => {
        //uploadedFiles.forEach(processUpload)
    },[uploadedFiles]);

    const updatedFile = useCallback((id,data) => {
        setUploadedFiles(prev=>{
            let unchangedFiles = prev.filter(file => file.id !== id);
            let updatedFile = {...prev.find(file => file.id === id),...data};
            let novo = [...unchangedFiles, updatedFile];
            //novo.sort((a,b) => { return a.name.localeCompare(b.name) }) //ordenacao por nome
            novo.sort((a,b) => { return (b.id - a.id) }) //ordenacao por id decrescente.
            return novo;
        });
    },[])

    const processUpload = useCallback(arquivo => {
        const formData = new FormData();
        formData.append("arquivo", arquivo.file, arquivo.name);
        formData.set('op','enviarfoto');
        formData.set('produto',id);
        Api.post('produtosfotos_upload.php', formData, {
            onUploadProgress: e => {
                const progress = parseInt(Math.round((e.loaded * 100) / e.total));
                updatedFile(arquivo.id,{progress});
            }
        }).then(response => {
            if (response.data.erro==='N') {
                updatedFile(arquivo.id,{ uploaded: true, url: response.data.url });
            }
            if (response.data.erro==='S') {
                updatedFile(arquivo.id,{ error: true, errorMsg: response.data.msg });
            }
        }).catch((response) => {
            updatedFile(arquivo.id,{ error: true, errorMsg: response.data.msg });
        });
    },[id,updatedFile])

    const handleUpload = useCallback((files) => {
        //files contem os arquivos dropados que devem ser adicionados na lista que tem o state.
        const newUploadedFiles = files.map(file => ({
            file,
            id: uniqueId(),
            name: file.name,
            readableSize: filesize(file.size),
            preview: URL.createObjectURL(file),
            progress: 0,
            uploaded: false,
            error: false,
            errorMsg: '',
            url: null
        }));
        setUploadedFiles( prev => [...prev, ...newUploadedFiles]);
        newUploadedFiles.forEach(processUpload); //processa somente os novos arquivos dropados.
    },[processUpload])

    function VerProduto() {
        var bodyFormData = new FormData();
        bodyFormData.set('op', 'verfotos');
        bodyFormData.set('produto', id);
        Api.post('produtos.php',bodyFormData)
        .then((response) => {
            if (response.data.erro==='N') {
                setProduto(response.data.msg);
                setImagens(response.data.msg.FOTOS);
            } else {
                setProduto({PRO_CODIGO:0});
                setImagens([]);
            }
        })
        .catch((response) => {
            console.log('CATCH',JSON.stringify(response.data));
            Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});  
        });
    }  

    useEffect(() => {
        VerProduto();
        // eslint-disable-next-line
    },[]);
        
    return <>
        {/* <BarraSuperior titulo='ENVIO DE FOTOS' linkleft='voltar' linkright=''/> */}
        <div className="bg-dark fixed-top">
            <div className="container-fluid limit-width p-0">
                <div className="row">
                    <div className="col-12 limit-height d-flex justify-content-between align-items-center">
                        <div className="">
                            <Link to="" onClick={() => navigate(-1)} className="link-icone"><i className="fa fa-2x fa-fw fa-chevron-left mx-2"></i></Link>
                        </div>
                        <div className="flex-grow-1 text-truncate"><p className="text-truncate fw-bold text-center text-light mt-3">ENVIO DE FOTOS</p></div>
                        <div className="">
                            <div className="link-icone me-3">&nbsp;</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <IfComponent condicional={produto.PRO_CODIGO<=0}>
            <div className="container limit-width vh-100 d-flex justify-content-center align-items-center">
                <p className="text-center lead"><span style={{fontSize: '7em'}} className="text-muted"><i className="fa fa-fw fa-record-vinyl"></i></span><br/>Não foi possível localizar o produto.</p>
            </div> 
        </IfComponent>
        <IfComponent condicional={produto.PRO_CODIGO>0}>
            <div className='container limit-width mt-5 py-2'>
                <ProdutoHeader 
                    imagens={imagens}
                    PRF_ARQUIVO={produto.PRF_ARQUIVO}
                    PRO_CODIGO={produto.PRO_CODIGO}
                    PRO_ESTOQUEATUAL={produto.PRO_ESTOQUEATUAL}
                    PRO_NOME={produto.PRO_NOME}
                    PRO_PRECOVENDA={produto.PRO_PRECOVENDA}
                    PRO_UNIDADE={produto.PRO_UNIDADE}
                />
                <div className='mb-3'>
                    <Upload accept='image/*' onUpload={handleUpload}/>
                </div>
                {!!uploadedFiles.length && (
                    <FileList files={uploadedFiles} />
                    // <FileList files={uploadedFiles} onDelete={handleDelete} />
                )}
            </div>
        </IfComponent>
        {/* <BarraInferior active='' /> */}
    </>
}

export default ProdutoFotosEnviar;