import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { InputMask } from 'primereact/inputmask';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import moment from 'moment';

// funcoes proprias.
import Api from '../Api';
import { AuthContext } from '../contextos/Auth';
import { ParametrosContext } from '../contextos/Parametros';
import IfComponent from '../componentes/IfComponent';

function Cliente(props) {
    //const {  } = useContext(AuthContext);
    const { usuarioLogin, logout } = useContext(AuthContext);
    const { parametros } = useContext(ParametrosContext);
    const { id } = useParams();
    const navigate = useNavigate();
    const [ showModal, setShowModal ] = useState(false);
    const [ cliente, setCliente ] = useState({
        CLI_BAIRRO: '',
        CLI_CEP: '',
        CLI_CIDADE: '',
        CLI_CNPJ: '',
        CLI_CODIGO: '',
        CLI_COMPLEMENTO: '',
        CLI_CONTATO: '',
        CLI_CPF: '',
        CLI_DTATUALIZACAO: '',
        CLI_DTNASC: '',
        CLI_EMAIL: '',
        CLI_ENDERECO: '',
        CLI_ESTADO: '',
        CLI_FONE1: '',
        CLI_FONE2: '',
        CLI_FONE3: '',
        CLI_IE: '',   
        CLI_NOME: '',
        CLI_NUMERO: '',
        CLI_RG: '',
        CLI_STATUS: '',
        CLI_TABELAPRECO: '',
        CLI_TIPOPESSOA: '',
        CLI_USUARIO: ''
    });
    
    const SelectItemsSTATUS = [
        {value: "A", label: "ATIVO" },
        {value: "I", label: "INATIVO" }
    ];

    const SelectItemsTIPOPESSOA = [
        {value: "F", label: "FISICA" },
        {value: "J", label: "JURIDICA" }
    ];

    const SelectItemsTABELAPRECO = [
        {value: "V", label: "VENDA" },
        {value: "1", label: "TABELA 1" },
        {value: "2", label: "TABELA 2" },
        {value: "3", label: "TABELA 3" },
        {value: "4", label: "TABELA 4" },
        {value: "5", label: "TABELA 5" },
        {value: "6", label: "TABELA 6" }
    ];

    function VerCliente() {
        var formData = new FormData();
        formData.set('op', 'ver');
        formData.set('cliente', id);

        Api.post('clientes.php',formData)
        .then((response) => {
            //setCliente(response.data.msg);
            if (response.data.erro==='N') {
                setCliente(response.data.msg);
            } else {
                //setCliente({CLI_CODIGO:0});
                const CLI_CODIGO=0;
                setCliente({...cliente, CLI_CODIGO});
                if (response.data.token==="expirado") {
                    logout();
                }
            }
        })
        .catch((response) => {
            console.log('CATCH',JSON.stringify(response.data));
            Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});  
        });
    }   
   
    useEffect(() => {
        VerCliente();
        // eslint-disable-next-line
    },[]);

    function btnEditar() {
        setShowModal(true);
    }

    const setCampo = (e) => {
        const { name, value } = e.target;
        let valor = value?value.toUpperCase():'';
        if (name==='CLI_EMAIL') {
            valor = valor.toLowerCase();
        }
        setCliente({...cliente, [name]: valor});
    }

    // const handleInputChange2 = (v,e) => {
    //     setCliente({...cliente, [e.event.target.name]: v.floatValue});
    // }  

    async function btnSalvar() {
        const formData = new FormData();
        formData.set('op','atualizar');
        formData.set('CLI_BAIRRO',cliente.CLI_BAIRRO || '');
        formData.set('CLI_CEP',cliente.CLI_CEP || '');
        formData.set('CLI_CIDADE',cliente.CLI_CIDADE || '');
        formData.set('CLI_CNPJ',cliente.CLI_CNPJ || '');
        formData.set('CLI_CODIGO',cliente.CLI_CODIGO);
        formData.set('CLI_COMPLEMENTO',cliente.CLI_COMPLEMENTO || '');
        formData.set('CLI_CONTATO',cliente.CLI_CONTATO || '');
        formData.set('CLI_CPF',cliente.CLI_CPF || '');
        formData.set('CLI_DTNASC',cliente.CLI_NASC || '');
        formData.set('CLI_EMAIL',cliente.CLI_EMAIL || '');
        formData.set('CLI_ENDERECO',cliente.CLI_ENDERECO || '');
        formData.set('CLI_ESTADO',cliente.CLI_ESTADO || '');
        formData.set('CLI_FONE1',cliente.CLI_FONE1 || '');
        formData.set('CLI_FONE2',cliente.CLI_FONE2 || '');
        formData.set('CLI_FONE3',cliente.CLI_FONE3 || '');
        formData.set('CLI_IE',cliente.CLI_IE || '');
        formData.set('CLI_NOME',cliente.CLI_NOME || '');
        formData.set('CLI_NUMERO',cliente.CLI_NUMERO || '');
        formData.set('CLI_RG',cliente.CLI_RG || '');
        formData.set('CLI_STATUS',cliente.CLI_STATUS);
        formData.set('CLI_TABELAPRECO',cliente.CLI_TABELAPRECO);
        formData.set('CLI_TIPOPESSOA',cliente.CLI_TIPOPESSOA);
        formData.set('CLI_USUARIO',usuarioLogin);
        const CLI_USUARIO = usuarioLogin;
        const CLI_DTATUALIZACAO = moment().format('DD/MM/YYYY');
        setCliente({...cliente, CLI_DTATUALIZACAO, CLI_USUARIO});
        const response = await Api.post('clientes.php',formData);
        if (response.data.erro==='N') {
            setShowModal(false); 
            Swal.fire({position: 'center', icon: 'success', title: response.data.msg, showConfirmButton: false, timer: 1500 });
        } else {
            response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'}); 
        }
    }

    return <>    
        <div className="bg-dark fixed-top">
            <div className="container-fluid limit-width p-0">
                <div className="row">
                    <div className="col-12 limit-height d-flex justify-content-between align-items-center">
                        <div className="">
                            <Link to="" onClick={() => navigate(-1)} className="link-icone"><i className="fa fa-2x fa-fw fa-chevron-left mx-2"></i></Link>
                        </div>
                        <div className="flex-grow-1 text-truncate"><p className="text-truncate fw-bold text-center text-light mt-3">DADOS DO CLIENTE</p></div>
                        <div className="">
                            <div className="link-icone me-3">
                                <IfComponent condicional={parametros.PAR_CLIALTERAR==="S"}>
                                    <Link to='' onClick={btnEditar}><i className="fa fa-2x fa-fw fa-pencil"></i></Link>
                                </IfComponent>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <IfComponent condicional={cliente.CLI_CODIGO<=0}>
            <div className="container limit-width vh-100 d-flex justify-content-center align-items-center">
                <p className="text-center lead"><span style={{fontSize: '7em'}} className="text-muted"><i className="fa fa-fw fa-record-vinyl"></i></span><br/>Não foi possível localizar o cliente.</p>
            </div>  
        </IfComponent>
        <IfComponent condicional={cliente.CLI_CODIGO>0}>
            <div className="container-fluid limit-width mt-5 py-2">
                <ul className="list-group mb-3">  
                    <li className="list-group-item p-2">    
                        <div className="row h34">
                            <div className="col-6">
                                <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">CÓDIGO</sup></p><p className="lh-1 m-0">{cliente.CLI_CODIGO}</p>
                            </div>    
                            <div className="col-6">
                                <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">STATUS</sup></p><p className="lh-1 m-0">{cliente.CLI_STATUS}</p>
                            </div>      
                        </div>  
                    </li>                 
                    <li className="list-group-item p-2"><div className="row h34"><p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">NOME / RAZÃO SOCIAL</sup></p><p className="lh-1 m-0">{cliente.CLI_NOME}</p></div></li>  
                    <li className="list-group-item p-2"><div className="row h34"><p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">CONTATO</sup></p><p className="lh-1 m-0">{cliente.CLI_CONTATO}</p></div></li>  
                    <li className="list-group-item p-2"><div className="row h34"><p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">EMAIL</sup></p><p className="lh-1 m-0">{cliente.CLI_EMAIL}</p></div></li>  
                    <li className="list-group-item p-2">    
                        <div className="row h34">
                            <div className="col-6">
                                <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">CNPJ</sup></p><p className="lh-1 m-0">{cliente.CLI_CNPJ}</p>
                            </div>      
                            <div className="col-6">
                                <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">CPF</sup></p><p className="lh-1 m-0">{cliente.CLI_CPF}</p>
                            </div>    
                        </div>  
                    </li> 
                    <li className="list-group-item p-2">    
                        <div className="row h34">
                            <div className="col-6">
                                <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">INSCRIÇÃO ESTADUAL</sup></p><p className="lh-1 m-0">{cliente.CLI_IE}</p>
                            </div>      
                            <div className="col-6">
                                <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">RG</sup></p><p className="lh-1 m-0">{cliente.CLI_RG}</p>
                            </div>    
                        </div>  
                    </li> 
                    <li className="list-group-item p-2">    
                        <div className="row h34">      
                            <div className="col-6">
                                <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">FONE 01</sup></p><p className="lh-1 m-0">{cliente.CLI_FONE1}</p>
                            </div>      
                            <div className="col-6">
                                <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">FONE 02</sup></p><p className="lh-1 m-0">{cliente.CLI_FONE2}</p>
                            </div>    
                        </div>  
                    </li> 
                    <li className="list-group-item p-2">    
                        <div className="row h34">      
                            <div className="col-6">
                                <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">FONE 03</sup></p><p className="lh-1 m-0">{cliente.CLI_FONE3}</p>
                            </div>      
                            <div className="col-6">
                                <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">DT. NASC.</sup></p><p className="lh-1 m-0">{cliente.CLI_DTNASC}</p>
                            </div>    
                        </div>  
                    </li>
                    <li className="list-group-item p-2"><div className="row h34"><p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">ENDEREÇO</sup></p><p className="lh-1 m-0">{cliente.CLI_ENDERECO}</p></div></li>
                    <li className="list-group-item p-2">    
                        <div className="row h34">      
                            <div className="col-4">
                                <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">NÚMERO</sup></p><p className="lh-1 m-0">{cliente.CLI_NUMERO}</p>
                            </div>      
                            <div className="col-4">
                                <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">COMPLEMENTO</sup></p><p className="lh-1 m-0">{cliente.CLI_COMPLEMENTO}</p>
                            </div>    
                        </div>  
                    </li> 
                    <li className="list-group-item p-2"><div className="row h34"><p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">BAIRRO</sup></p><p className="lh-1 m-0">{cliente.CLI_BAIRRO}</p></div></li>
                    <li className="list-group-item p-2"><div className="row h34"><p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">CIDADE</sup></p><p className="lh-1 m-0">{cliente.CLI_CIDADE}</p></div></li>
                    <li className="list-group-item p-2">    
                        <div className="row h34">
                            <div className="col-6">
                                <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">ESTADO</sup></p><p className="lh-1 m-0">{cliente.CLI_ESTADO}</p>
                            </div>    
                            <div className="col-4">
                                <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">CEP</sup></p><p className="lh-1 m-0">{cliente.CLI_CEP}</p>
                            </div>    
                        </div>  
                    </li> 
                    <li className="list-group-item p-2">    
                        <div className="row h34">
                            <div className="col-6">
                                <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">TIPO PESSOA</sup></p><p className="lh-1 m-0">{cliente.CLI_TIPOPESSOA}</p>
                            </div>    
                            <div className="col-4">
                                <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">TABELA DE PREÇO</sup></p><p className="lh-1 m-0">{cliente.CLI_TABELAPRECO}</p>
                            </div>    
                        </div>  
                    </li> 
                    <li className="list-group-item p-2">    
                        <div className="row h34">
                            <div className="col-6">
                                <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">DATA ATUALIZAÇÃO</sup></p><p className="lh-1 m-0">{cliente.CLI_DTATUALIZACAO}</p>
                            </div>    
                            <div className="col-4">
                                <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">USUÁRIO</sup></p><p className="lh-1 m-0">{cliente.CLI_USUARIO}</p>
                            </div>    
                        </div>  
                    </li> 

                    <li className="list-group-item p-2"><p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">OBSERVAÇÕES</sup></p><span className="lh-1 m-0"><section dangerouslySetInnerHTML={{ __html: cliente.CLI_OBSERVACOES || '-' }} /></span></li>
                </ul>
                <div className="row gx-2 mb-5">
                    
                </div>             
            </div>
        </IfComponent>
        {/* <BarraInferior active=""/> */}

        <Modal show={showModal} fullscreen={true} backdrop="static">
            <Modal.Header className="bg-dark text-light p-0 limit-height">
                <div className="container limit-width">
                    <Modal.Title><h5 className="modal-title">EDITAR CLIENTE</h5></Modal.Title>
                </div>
            </Modal.Header>

            <Modal.Body className="p-0">
                <div className="container limit-width">
                    <div className="row mb-0 mt-0 gx-1 p-inputtext-sm pett-input mt-2">
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="CLI_CODIGO" value={cliente.CLI_CODIGO || ''} onChange={(e) => setCampo(e)} disabled readOnly/>
                                <label htmlFor="CLI_CODIGO">CÓDIGO</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <Dropdown className="w-100" name="CLI_STATUS" value={cliente.CLI_STATUS || ''} options={SelectItemsSTATUS} onChange={(e) => setCampo(e)} placeholder="Selecione"/>
                                <label htmlFor="CLI_STATUS">STATUS</label>
                            </span>
                        </div>
                        <div className="col-12 mb-1">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="CLI_NOME" value={cliente.CLI_NOME || ''} onChange={(e) => setCampo(e)} />
                                <label htmlFor="CLI_NOME">NOME / RAZÃO SOCIAL</label>
                            </span>
                        </div>
                        <div className="col-12 mb-1">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="CLI_CONTATO" value={cliente.CLI_CONTATO || ''} onChange={(e) => setCampo(e)} />
                                <label htmlFor="CLI_CONTATO">CONTATO</label>
                            </span>
                        </div>
                        <div className="col-12 mb-1">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="CLI_EMAIL" value={cliente.CLI_EMAIL || ''} onChange={(e) => setCampo(e)} />
                                <label htmlFor="CLI_EMAIL">EMAIL</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputMask className="w-100" name="CLI_CNPJ" mask="99.999.999/9999-99" value={cliente.CLI_CNPJ || ''} onChange={(e) => setCampo(e)} ></InputMask>
                                <label htmlFor="CLI_CNPJ">CNPJ</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputMask className="w-100" name="CLI_CPF" mask="999.999.999-99" value={cliente.CLI_CPF || ''} onChange={(e) => setCampo(e)}></InputMask>
                                <label className="" htmlFor="CLI_CPF">CPF</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="CLI_IE" value={cliente.CLI_IE || ''} onChange={(e) => setCampo(e)} />
                                <label htmlFor="CLI_IE">INSCRIÇÃO ESTADUAL</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="CLI_RG" value={cliente.CLI_RG || ''} onChange={(e) => setCampo(e)} />
                                <label htmlFor="CLI_RG">RG</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputMask className="w-100" name="CLI_FONE1" mask="(999) 99999-9999" value={cliente.CLI_FONE1 || ''} onChange={(e) => setCampo(e)}></InputMask>
                                <label htmlFor="CLI_FONE1">FONE 01</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputMask className="w-100" name="CLI_FONE2" mask="(999) 99999-9999"  value={cliente.CLI_FONE2 || ''} onChange={(e) => setCampo(e)}></InputMask>
                                <label htmlFor="CLI_FONE2">FONE 02</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputMask className="w-100" name="CLI_FONE3" mask="(999) 99999-9999"  value={cliente.CLI_FONE3 || ''} onChange={(e) => setCampo(e)}></InputMask>
                                <label htmlFor="CLI_FONE3">FONE 03</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputMask className="w-100" name="CLI_DTNASC" mask="99/99/9999"  value={cliente.CLI_DTNASC || ''} onChange={(e) => setCampo(e)}></InputMask>
                                <label htmlFor="CLI_DTNASC">DT. NASC.</label>
                            </span>
                        </div>
                        <div className="col-12 mb-1">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="CLI_ENDERECO" value={cliente.CLI_ENDERECO || ''} onChange={(e) => setCampo(e)} />
                                <label htmlFor="CLI_ENDERECO">ENDEREÇO</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="CLI_NUMERO" value={cliente.CLI_NUMERO || ''} onChange={(e) => setCampo(e)} />
                                <label htmlFor="CLI_NUMERO">NÚMERO</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputMask className="w-100" name="CLI_CEP" mask="99999-999" value={cliente.CLI_CEP || ''} onChange={(e) => setCampo(e)}></InputMask>
                                <label htmlFor="CLI_CEP">CEP</label>
                            </span>
                        </div>
                        <div className="col-12 mb-1">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="CLI_COMPLEMENTO" value={cliente.CLI_COMPLEMENTO || ''} onChange={(e) => setCampo(e)} />
                                <label htmlFor="CLI_COMPLEMENTO">COMPLEMENTO</label>
                            </span>
                        </div>
                        <div className="col-12 mb-1">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="CLI_BAIRRO" value={cliente.CLI_BAIRRO || ''} onChange={(e) => setCampo(e)} />
                                <label htmlFor="CLI_BAIRRO">BAIRRO</label>
                            </span>
                        </div>
                        <div className="col-12 mb-1">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="CLI_CIDADE" value={cliente.CLI_CIDADE || ''} onChange={(e) => setCampo(e)} />
                                <label htmlFor="CLI_CIDADE">CIDADE</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="CLI_ESTADO" value={cliente.CLI_ESTADO || ''} onChange={(e) => setCampo(e)} />
                                <label htmlFor="CLI_ESTADO">ESTADO</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <Dropdown className="w-100" name="CLI_TABELAPRECO" value={cliente.CLI_TABELAPRECO || ''} options={SelectItemsTABELAPRECO} onChange={(e) => setCampo(e)} placeholder="Selecione"/>
                                <label htmlFor="CLI_TABELAPRECO">TABELA DE PREÇO</label>
                            </span>
                        </div>
                        <div className="col-6 mb-4">
                            <span className="p-float-label pett-label">
                                <Dropdown className="w-100" name="CLI_TIPOPESSOA" value={cliente.CLI_TIPOPESSOA || ''} options={SelectItemsTIPOPESSOA} onChange={(e) => setCampo(e)} placeholder="Selecione"/>
                                <label htmlFor="CLI_TIPOPESSOA">TIPO PESSOA</label>
                            </span>
                        </div>
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer className='bg-dark text-light p-0 limit-height d-flex justify-content-center'>
                <div className="container limit-width btn-group p-0">
                    <Button className="w-100" variant="danger" onClick={() => setShowModal(false)}>Cancelar</Button>
                    <Button className="w-100" variant="success" onClick={() => btnSalvar()}><i className="fa fa-fw fa-check"></i> Salvar</Button>
                </div>
            </Modal.Footer>
        </Modal>

    </>
}

export default Cliente;