import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

import { AuthProvider, AuthContext } from './contextos/Auth';
import { ParametrosProvider } from './contextos/Parametros';
import Cliente from './paginas/Cliente';
import Clientes from './paginas/Clientes';
import ErrorPage from './paginas/ErrorPage';
import Home from './paginas/Home';
import Login from './paginas/Login';
import Metas from './paginas/Metas';
import Produto from './paginas/Produto';
import Produtos from './paginas/Produtos';
import ProdutoFotos from './paginas/ProdutoFotos';
import ProdutoFotosEnviar from './paginas/ProdutoFotosEnviar';
import Receber from './paginas/Receber';
import ReceberId from './paginas/ReceberId';
import Servico from './paginas/Servico';
import Servicos from './paginas/Servicos';
import Venda from './paginas/Venda';
import VendaConferencia from './paginas/VendaConferencia';
import Vendas from './paginas/Vendas';
import VendasConferencias from './paginas/VendasConferencias';
import { ReactComponent as IcoWait } from "./Imagens/wait.svg";

import "primereact/resources/themes/mdc-light-deeppurple/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";                                //icons
import "./App.css"; //pett

function App() {
    const Privado = ({ children }) => {
        const { logado, usuarioStatus, carregando } = useContext(AuthContext);

        if (carregando) {
            return <>
                <div className="container limit-width vh-100 d-flex justify-content-center align-items-center">
                    <p className="text-center lead"><IcoWait className="ico-150 ico-animado" style={{fontSize: '7em'}}/><br/>Carregando...</p>
                </div>  
            </>
        }

        if (usuarioStatus==='I') {
            return <Navigate to='/confirmarcadastro' />
        }
        
        if (!logado) {
            return <Navigate to="/login" replace={true}/>
        }

        return children;
    };

    return (
        <Router>
            <ParametrosProvider>
                <AuthProvider>
                    <Routes>
                        <Route exact path='/' element={<Navigate replace to="/home" />} /> 
                        <Route exact path='/login' element={<Login />} />
                        <Route exact path='/cliente/:id' element={<Privado><Cliente /></Privado>} />
                        <Route exact path='/clientes' element={<Privado><Clientes /></Privado>} />
                        <Route exact path='/metas' element={<Privado><Metas /></Privado>} />
                        <Route exact path='/produtos' element={<Privado><Produtos /></Privado>} />
                        <Route exact path='/produto/:id' element={<Privado><Produto /></Privado>} />
                        <Route exact path='/produtofotos/:id' element={<Privado><ProdutoFotos /></Privado>} />
                        <Route exact path='/produtofotosenviar/:id' element={<Privado><ProdutoFotosEnviar /></Privado>} />
                        <Route exact path='/receber' element={<Privado><Receber /></Privado>} />
                        <Route exact path='/receber/:id' element={<Privado><ReceberId /></Privado>} />
                        <Route exact path='/servicos' element={<Privado><Servicos /></Privado>} />
                        <Route exact path='/servico/:id' element={<Privado><Servico /></Privado>} />
                        <Route exact path='/vendas' element={<Privado><Vendas /></Privado>} />
                        <Route exact path='/vendasconferencias' element={<Privado><VendasConferencias /></Privado>} />
                        <Route exact path='/venda/:id' element={<Privado><Venda /></Privado>} />
                        <Route exact path='/vendaconferencia/:id' element={<Privado><VendaConferencia /></Privado>} />
                        <Route exact path='/home' element={<Privado><Home /></Privado>} />
                        <Route path='*' element={<ErrorPage />} />
                    </Routes>
                </AuthProvider>
            </ParametrosProvider>
        </Router>
    );
}

export default App;
