import React, { useEffect, useState, useContext, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { NumericFormat } from 'react-number-format';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Button as ButtonP } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { SwipeableList, SwipeableListItem, SwipeAction, TrailingActions, Type as ListType } from 'react-swipeable-list';
import 'react-swipeable-list/dist/styles.css';

// funcoes proprias.
import Api from '../Api';
import { AuthContext } from '../contextos/Auth';
import IfComponent from '../componentes/IfComponent';
import VendasItens from '../componentes/VendasItens';
import ProdutosLoc from '../componentes/ProdutosLoc';
import CondicoesLoc from '../componentes/CondicoesLoc';

function Venda(props) {
    const { logout } = useContext(AuthContext);
    const { id } = useParams();
    const navigate = useNavigate();
    const inputRef = useRef(null);
    const [ venda, setVenda ] = useState({});
    const [ vendaModal, setVendaModal ] = useState({});
    const [ itens, setItens ] = useState([]);
    const [ vendasItens, setVendasItens ] = useState({}); //usado no modal.
    const [ showModal, setShowModal ] = useState(false); //para inserir item.
    const [ showModalPedido, setShowModalPedido ] = useState(false); //para inserir item.
    const [ tituloModal, setTituloModal ] = useState('INSERIR ITEM'); //usado no modal.
    const [ opModal, setOpModal ] = useState('inseriritem'); //usado no modal.
    const [ codigoBarras, setCodigoBarras ] = useState(''); //para insercao rápida de item 

    const [ showProdutosLoc, setShowProdutosLoc ] = useState(false);
    const [ showCondicoesLoc, setShowCondicoesLoc ] = useState(false);

    function VerVenda() {
        var formData = new FormData();
        formData.set('op', 'ver');
        formData.set('venda', id);

        Api.post('vendas.php',formData)
        .then((response) => {
            setVenda(response.data.msg);
            if (response.data.erro==='N') {
                setVenda(response.data.msg);
                setItens(response.data.msg.ITENS);
            } else {
                setVenda({VED_CODIGO:0});
                setItens({});
                if (response.data.token==='expirado') {
                    logout();
                }
            }
        })
        .catch((response) => {
            console.log('CATCH',JSON.stringify(response.data));
            Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});  
        });
    }   
   
    useEffect(() => {
        VerVenda();
        // eslint-disable-next-line
    },[]);

    async function btnSalvarPedido() {
        const formData = new FormData();
        //formData.set('op','inseriritem');
        formData.set('op','alterarvenda');
        formData.set('VED_CODIGO',vendaModal.VED_CODIGO || '');
        formData.set('VED_CONDICAO',vendaModal.VED_CONDICAO || '');
        formData.set('VED_OBSERVACOES',vendaModal.VED_OBSERVACOES || '');
        const response = await Api.post('vendas.php',formData);
        if (response.data.erro==='N') {
            setVenda(response.data.msg);
            setShowModalPedido(false);
        }
        if (response.data.erro==='S') {
            response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
        }
    }

    async function btnSalvarItem() {
        const formData = new FormData();
        formData.set('op',opModal); //pode ser inseriritem ou alteraritem.
        formData.set('VEI_VENDA',venda.VED_CODIGO || '');
        formData.set('VEI_CODIGO',vendasItens.VEI_CODIGO || '');
        formData.set('VEI_PRODUTO',vendasItens.VEI_PRODUTO || '');
        formData.set('VEI_DESCRICAO',vendasItens.VEI_DESCRICAO || '');
        formData.set('VEI_QUANTIDADE',vendasItens.VEI_QUANTIDADE || '');
        formData.set('VEI_DESCPORC',vendasItens.VEI_DESCPORC || '');
        formData.set('VEI_DESCVALOR',vendasItens.VEI_DESCVALOR || '');
        formData.set('VEI_COMPLEMENTO',vendasItens.VEI_COMPLEMENTO || '');
        formData.set('VEI_VALORUNIT',vendasItens.VEI_VALORUNIT || '');
        formData.set('ALTERAR_VALOR_UNITARIO',process.env.ALTERAR_VALOR_UNITARIO || '');
        formData.set('DESCONTO_EM_VALOR',process.env.REACT_APP_DESCONTO_EM_VALOR || '');
        const response = await Api.post('vendas.php',formData);
        if (response.data.erro==='N') {
            //Swal.fire({title: 'Yessss!', text: 'Item inserido com sucesso!', icon: 'success', showConfirmButton: false, timer: 1500});
            setVendasItens({});
            setVenda(response.data.msg);
            setItens(response.data.msg.ITENS);
            setShowModal(false);
        } 
        if (response.data.erro==='S') {
            response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
        }
    }

    function fecharPedido() {
        Swal.fire({
            position: 'center',
            icon: 'question',
            title: 'Fechar o pedido?',
            text: 'Tem certeza que deseja fechar o pedido '+venda.VED_CODIGO+'?',
            showCancelButton: true,
            confirmButtonText: 'Sim, pode fechar!',
            cancelButtonText: 'Ainda Não!',
            confirmButtonColor: '#212529'
        }).then((result) => {
            if (result.isConfirmed) {
                const formData = new FormData();
                formData.set('op','fecharvenda');
                formData.set('venda',venda.VED_CODIGO || '');
                Api.post('vendas.php',formData).then((response) => {                
                    if (response.data.erro==='N') {
                        Swal.fire({title: 'Yessss!', text: 'Venda finalizada com sucesso.', icon: 'success', showConfirmButton: false, timer: 1500});
                        setVenda(response.data.msg)
                    } 
                    if (response.data.erro==='S') {
                        response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
                    }
                })
            }
        });
    }

    function inutilizarPedido() {
        Swal.fire({
            position: 'center',
            icon: 'question',
            title: 'Inutilizar o pedido?',
            text: 'Tem certeza que deseja inutilizar o pedido '+venda.VED_CODIGO+'?',
            showCancelButton: true,
            confirmButtonText: 'Sim, quero inutilizar!',
            cancelButtonText: 'Ainda Não!',
            confirmButtonColor: '#212529'
        }).then((result) => {
            if (result.isConfirmed) {
                const formData = new FormData();
                formData.set('op','inutilizar');
                formData.set('venda',venda.VED_CODIGO || '');
                Api.post('vendas.php',formData).then((response) => {                
                    if (response.data.erro==='N') {
                        Swal.fire({title: 'Yessss!', text: 'Venda inutilizada com sucesso.', icon: 'success', showConfirmButton: false, timer: 1500});
                        setVenda(response.data.msg)
                    } 
                    if (response.data.erro==='S') {
                        response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
                    }
                })
            }
        });
    }
    
    const setCampo = (e) => {
        const { name, value } = e.target;
        let valor = value?value.toUpperCase():'';
        setVendasItens({...vendasItens, [name]: valor});
    }

    const setCampoBlob = (e) => {
        const { name, value } = e.target;
        //let valor = value?value.toUpperCase():'';
        setVendaModal({...vendaModal, [name]: value});
    }

    const setCampoValor = (e) => {
        let { name, value } = e.target;
        setVendasItens({...vendasItens, [name]: value});
    }  

    //funcoes do modal de produtos.
    function ProdutosLocClose(event, data) {
        setVendasItens({...vendasItens, 'VEI_PRODUTO': data.PRO_CODIGO, 'VEI_DESCRICAO': data.PRO_NOME, 'VEI_DESCMAX': data.PRO_DESCMAX, 'VEI_VALORUNIT': data.PRO_PRECOVENDA, 'VEI_QUANTIDADE': '1' })
        setShowProdutosLoc(false);
    }

    function ProdutosLocOpen(event) {
        setShowProdutosLoc(true);
    }

    //funcoes do modal de condicoes.
    function CondicoesLocClose(event, data) {
        setVendaModal({...vendaModal, 'VED_CONDICAO': data.CPG_CODIGO, 'CPG_DESCRICAO': data.CPG_DESCRICAO })
        setShowCondicoesLoc(false);
    }

    function CondicoesLocOpen(event) {
        setShowCondicoesLoc(true);
    }   

    //https://bestofreactjs.com/repo/marekrozmus-react-swipeable-list-react-sortable-list
    // const leadingActions = ({id}) => (
    //     <LeadingActions>
    //         <SwipeAction destructive={false} onClick={() => console.info(id, 'clique estrela')}>
    //             <div className="d-flex align-items-center bg-success text-light text-center p-3"><i className="fa fa-fw fa-star text-warning"></i> Estrela</div>
    //         </SwipeAction>     
    //     </LeadingActions>
    // );
    
    //para a exclusao do item
    const trailingActions = ({item}) => (
        <TrailingActions>
            <SwipeAction destructive={false} onClick={() => {
                Swal.fire({
                    position: 'center',
                    icon: 'question',
                    title: 'Confirma Exclusão?',
                    text: 'Tem certeza que deseja excluir '+item.VEI_DESCRICAO+'?',
                    showCancelButton: true,
                    confirmButtonText: 'Sim, quero!',
                    cancelButtonText: 'Nãããoooooo',
                    confirmButtonColor: '#212529'
                }).then((result) => {
                    if (result.isConfirmed) {
                        var formData = new FormData();
                        formData.set('op', 'excluiritem');
                        formData.set('VEI_CODIGO', item.VEI_CODIGO);
                        Api.post('vendas.php',formData).then((response) => {
                            if (response.data.erro==='N') {
                                //Swal.fire({ position: 'center', icon: 'success', title: response.data.msg, showConfirmButton: false, timer: 1500 });
                                setVenda(response.data.msg);
                                setItens(response.data.msg.ITENS);
                            } else {
                                response.data.token==="expirado"?logout():Swal.fire({ title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529' });
                            }
                        }).catch(({response}) => {
                            console.log('CATCH',JSON.stringify(response));
                        });
                    }
                });
            }}>
                <div className="d-flex align-items-center bg-danger text-light text-center p-3" style={{cursor: 'pointer'}}><i className="fa fa-fw fa-trash"></i></div>
            </SwipeAction>
            <SwipeAction destructive={false} onClick={() => { editarItem(item); }}>
                <div className="d-flex align-items-center bg-warning text-center p-3" style={{cursor: 'pointer'}}><i className="fa fa-fw fa-pencil"></i></div>
            </SwipeAction>
        </TrailingActions>
    );

    //para abrir a insercao do item.
    function inserirItem(item) {
        setTituloModal('INSERIR ITEM');
        setOpModal('inseriritem');
        setVendasItens({});
        setShowModal(true);
    }

    //para abrir a edicao do item.
    function editarItem(item) {
        if (venda.VED_STATUS==='A') {
            setTituloModal('EDITAR ITEM');
            setOpModal('alteraritem');
            setVendasItens(item);
            setShowModal(true);
        }
    }

    function modalPedido() {
        setVendaModal(venda);
        setShowModalPedido(true);
    }

    const codigoBarrasKeyDown = (e) => {
        if (e.keyCode === 13) {
            inserirItemPeloCodigo();
        }
    }  

    async function inserirItemPeloCodigo() {
        const formData = new FormData();
        formData.set('op','inseriritemcodigo');
        formData.set('VEI_CODIGO','0');
        formData.set('VEI_VENDA',venda.VED_CODIGO || '');
        formData.set('VEI_PRODUTO',codigoBarras || '');
        formData.set('VEI_DESCRICAO','');
        formData.set('VEI_QUANTIDADE','1');
        formData.set('VEI_DESCPORC','0');
        formData.set('VEI_DESCVALOR','0');
        formData.set('VEI_COMPLEMENTO','');
        formData.set('ALTERAR_VALOR_UNITARIO',process.env.ALTERAR_VALOR_UNITARIO || '');
        formData.set('DESCONTO_EM_VALOR',process.env.REACT_APP_DESCONTO_EM_VALOR || '');
        const response = await Api.post('vendas.php',formData);
        if (response.data.erro==='N') {
            //Swal.fire({title: 'Yessss!', text: 'Item inserido com sucesso!', icon: 'success', showConfirmButton: false, timer: 1500});
            setVenda(response.data.msg);
            setItens(response.data.msg.ITENS);
            setCodigoBarras('');
        } 
        if (response.data.erro==='S') {
            response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
        }
    }

    return <>    
        <div className="bg-dark fixed-top">
            <div className="container-fluid limit-width p-0">
                <div className="row">
                    <div className="col-12 limit-height d-flex justify-content-between align-items-center">
                        <div className="">
                            <Link to="" onClick={() => navigate(-1)} className="link-icone"><i className="fa fa-2x fa-fw fa-chevron-left mx-2"></i></Link>
                        </div>
                        <div className="flex-grow-1 text-truncate"><p className="text-truncate fw-bold text-center text-light mt-3">PEDIDO DE VENDA</p></div>
                        <div className="">
                            {/* <div className="link-icone me-3">&nbsp;</div> */}
                            {
                                venda.VED_STATUS==='A' && venda.VED_VIAINTERNETSTATUS==='A'
                                    ? <Link to="" onClick={() => modalPedido()} className="link-icone"><i className="fa fa-2x fa-fw fa-pencil mx-2"></i></Link>
                                    : <span className="link-icone"><i className="fa fa-2x fa-fw fa-nenhum mx-2"></i></span>
                            }                                    
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <IfComponent condicional={venda.VED_CODIGO<=0}>
            <div className="container limit-width vh-100 d-flex justify-content-center align-items-center">
                <p className="text-center lead"><span style={{fontSize: '7em'}} className="text-muted"><i className="fa fa-fw fa-record-vinyl"></i></span><br/>Não foi possível localizar o pedido.</p>
            </div>  
        </IfComponent>
        <IfComponent condicional={venda.VED_CODIGO>0}>
            <div className="container-fluid limit-width mt-5 pt-2">
                <ul className="list-group mb-3">  
                    <li className="list-group-item p-2">    
                        <div className="row">      
                            <div className="col-4">
                                <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">CÓDIGO</sup></p><p className="lh-1 m-0">{venda.VED_CODIGO}</p>
                            </div>      
                            <div className="col-4">
                                <IfComponent condicional={venda.VED_STATUS==='A'}>
                                    <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">STATUS</sup></p><p className="lh-1 m-0 fw-bold text-primary">ABERTO</p>
                                </IfComponent>
                                <IfComponent condicional={venda.VED_STATUS==='F'}>
                                    <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">STATUS</sup></p><p className="lh-1 m-0 fw-bold text-success">FINALIZADO</p>
                                </IfComponent>
                                <IfComponent condicional={venda.VED_STATUS==='C'}>
                                    <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">STATUS</sup></p><p className="lh-1 m-0 fw-bold text-danger">CANCELADO</p>
                                </IfComponent>
                                <IfComponent condicional={venda.VED_STATUS==='I'}>
                                    <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">STATUS</sup></p><p className="lh-1 m-0 fw-bold text-warning">INUTILIZADO</p>
                                </IfComponent>
                            </div>      
                            <div className="col-4">
                                <IfComponent condicional={venda.VED_VIAINTERNETSTATUS==='A'}>
                                    <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">STATUS MOBILE</sup></p><p className="lh-1 m-0 fw-bold text-primary">ABERTO</p>
                                </IfComponent>
                                <IfComponent condicional={venda.VED_VIAINTERNETSTATUS==='F'}>
                                    <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">STATUS MOBILE</sup></p><p className="lh-1 m-0 fw-bold text-success">FINALIZADO</p>
                                </IfComponent>
                            </div>
                        </div>  
                    </li> 
                    <li className="list-group-item p-2"><p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">CLIENTE</sup></p><p className="lh-1 m-0">{venda.VED_CLIENTE+' - '+venda.VED_NOME}</p></li>  
                    <li className="list-group-item p-2"><p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">CONDIÇÃO</sup></p><p className="lh-1 m-0">{venda.VED_CONDICAO+' - '+venda.CPG_DESCRICAO}</p></li>  
                    <li className="list-group-item p-2">    
                        <div className="row">      
                            <div className="col-6">
                                <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">DATA CADASTRO</sup></p><p className="lh-1 m-0">{venda.VED_DTCADASTRO} {venda.VED_HRCADASTRO}</p>
                            </div>      
                            <div className="col-6">
                                <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">DATA ATUALIZAÇÃO</sup></p><p className="lh-1 m-0">{venda.VED_DTATUALIZACAO} {venda.VED_HRATUALIZACAO}</p>
                            </div>    
                        </div>  
                    </li> 
                    <li className="list-group-item p-2">    
                        <div className="row">      
                            <div className="col-6">
                                <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">DESCONTO</sup></p>
                                <p className="lh-1 m-0">
                                    <NumericFormat 
                                        value={parseFloat(venda.VED_DESCVALOR)}
                                        // isNumericString={true}
                                        displayType="text"
                                        prefix="R$ "
                                        thousandsGroupStyle="thousand"
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator="."
                                        allowNegative={true}
                                    />
                                </p>
                            </div>      
                            <div className="col-6">
                                <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">VALOR TOTAL (TABELA {venda.VED_TABELAPRECO})</sup></p>
                                <p className="lh-1 m-0 text-danger">
                                    <NumericFormat 
                                        value={parseFloat(venda.VED_VALORTOTAL)}
                                        // isNumericString={true}
                                        displayType="text"
                                        prefix="R$ "
                                        thousandsGroupStyle="thousand"
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator="."
                                        allowNegative={true}
                                    />
                                </p>
                            </div>    
                        </div>  
                    </li> 
                    <li className="list-group-item p-2"><p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">OBSERVAÇÕES</sup></p><span className="lh-1 m-0"><section dangerouslySetInnerHTML={{ __html: venda.VED_OBSERVACOES || '-' }} /></span></li>
                </ul>
            </div>
        </IfComponent>

        <div className="container-fluid limit-width">
            <div className="row">
                <IfComponent condicional={itens.length > 0 && venda.VED_STATUS==='A' && venda.VED_VIAINTERNETSTATUS==='A'}>
                    <div className="col-6 mb-3 pe-1">
                        <Link to="" className="btn btn-danger w-100" onClick={() => inutilizarPedido()}><i className="fa fa-fw fa-cancel me-2"></i> Inutilizar pedido</Link>
                    </div>
                    <div className="col-6 mb-3 ps-1">
                        <Link to="" className="btn btn-primary w-100" onClick={() => fecharPedido()}><i className="fa fa-fw fa-paper-plane me-2"></i> Fechar e enviar pedido</Link>
                    </div>
                </IfComponent>
                <div className="col-12 d-flex justify-content-between align-items-center bg-secondary bg-opacity-25">
                    <IfComponent condicional={venda.VED_STATUS==='A' && venda.VED_VIAINTERNETSTATUS==='A'}>
                        <div className="me-2">
                            <p className="text-truncate text-black fs-6 fw-bolder mt-3">Adicionar</p>
                        </div>
                        <div className="p-inputgroup p-inputtext-sm">
                            <InputText className="w-100" name="CODIGOBARRAS" placeholder="Digite o código e pressione ENTER" value={codigoBarras || ''} onChange={(e) => setCodigoBarras(e.target.value)} onKeyDown={(e) => codigoBarrasKeyDown(e)}/>
                            <ButtonP className="px-3 p-button-success" icon="pi pi-search" onClick={() => inserirItem()}/>
                        </div>          
                    </IfComponent>
                    <IfComponent condicional={venda.VED_STATUS!=='A' || venda.VED_VIAINTERNETSTATUS!=='A'}>
                        <div className="me-2">
                            <p className="text-truncate text-black fs-6 fw-bolder mt-3">Itens</p>
                        </div>
                    </IfComponent>
                </div>


                {/* <div className="col-12 d-flex justify-content-between align-items-center bg-secondary bg-opacity-25 limit-height">
                    <div className="flex-grow-1"><p className="text-truncate text-black fs-3 fw-bolder mt-3">Itens</p></div>
                    <div className="link-icone">
                        {
                            venda.VED_STATUS==='A' && venda.VED_VIAINTERNETSTATUS==='A'
                            // venda.VED_STATUS==='A'
                                ? <Link to="" className="text-black" onClick={() => inserirItem()}><i className="fa fa-2x fa-fw fa-plus"></i></Link>
                                : <span>&nbsp;</span>
                        }
                    </div>
                    <div className="col-12 mb-2 p-inputtext-sm">
                        <span className="p-float-label">
                            <InputText className="w-100 p-inputwrapper-filled" name="CODIGOBARRAS" value={codigoBarras || ''} onChange={(e) => setCodigoBarras(e.target.value)} onKeyDown={(e) => codigoBarrasKeyDown(e)}/>
                            <label htmlFor="CODIGOBARRAS">CODIGO DE BARRAS</label>
                        </span>
                    </div>                    
                </div> */}

            </div>
        </div>

        <IfComponent condicional={itens.length > 0}>
            <div className="container-fluid limit-width p-0 pb-2">
                <div className="mb-5">
                    <SwipeableList fullSwipe={false} type={ListType.IOS}>
                        {                    
                            itens.map(item => (
                                <SwipeableListItem key={item.VEI_CODIGO} trailingActions={trailingActions({ item })} blockSwipe={(venda.VED_STATUS==='A' && venda.VED_VIAINTERNETSTATUS==='A')?false:true}>
                                    <VendasItens
                                        key={item.VEI_CODIGO}
                                        VEI_PRODUTO={item.VEI_PRODUTO}
                                        VEI_DESCRICAO={item.VEI_DESCRICAO}
                                        VEI_QUANTIDADE={item.VEI_QUANTIDADE}
                                        VEI_SEQUENCIA={item.VEI_SEQUENCIA}
                                        VEI_UNIDADE={item.VEI_UNIDADE}
                                        VEI_VALORTOTAL={item.VEI_VALORTOTAL}
                                        VEI_VALORUNIT={item.VEI_VALORUNIT}
                                        edicao={()=>editarItem(item)}
                                    />
                                </SwipeableListItem>
                            ))
                        }
                    </SwipeableList>
                </div>             
            </div>             
        </IfComponent>

        <Modal show={showModalPedido} fullscreen={true} backdrop="static">
            <Modal.Header className="bg-dark text-light p-0 limit-height">
                <div className="container limit-width">
                    <Modal.Title><h5 className="modal-title">EDITAR PEDIDO</h5></Modal.Title>
                </div>
            </Modal.Header>

            <Modal.Body className="p-0">
                <div className="container limit-width mt-3">
                    <div className="row">

                        <div className="col-12 mb-2 p-inputtext-sm pett-input">
                            <div className="p-inputgroup">
                                <span className="p-float-label pett-label w-25">
                                    <InputText className="" name="VED_CONDICAO" value={vendaModal.VED_CONDICAO || ''} readOnly/>
                                    <label htmlFor="VED_CONDICAO">CONDIÇÃO</label>
                                </span>
                                <InputText className="w-75" name="CPG_DESCRICAO" value={vendaModal.CPG_DESCRICAO || ''} readOnly/>
                                <ButtonP className="px-3 p-button-success" icon="pi pi-search" onClick={(e) => CondicoesLocOpen(e)}/>
                            </div>          
                        </div>

                        <div className="col-12 mb-2 p-inputtext-sm pett-input">
                            <span className="p-float-label pett-label">
                                <InputTextarea className="w-100" name="VED_OBSERVACOES" value={vendaModal.VED_OBSERVACOES || ''} onChange={(e) => setCampoBlob(e)} autoResize/>
                                <label htmlFor="VED_OBSERVACOES">OBSERVAÇÕES</label>
                            </span>
                        </div>

                        {/* <div className="col-12">
                            <Button className="w-100 my-4" variant="success" onClick={() => btnSalvarPedido()}><i className="fa fa-fw fa-check"></i> Salvar</Button>
                            <Button className="w-100 mb-3" variant="danger" onClick={() => setShowModalPedido(false)}>Cancelar</Button>
                        </div> */}
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer className='bg-dark limit-height p-0 d-flex justify-content-center'>
                <div className="container limit-width p-0">
                    <div className="btn-group w-100">
                        <Button variant="danger" onClick={() => setShowModalPedido(false)}>Cancelar</Button>
                        <Button variant="success" onClick={() => btnSalvarPedido()}><i className="fa fa-fw fa-check"></i> Salvar</Button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>

        <Modal show={showModal} fullscreen={true} backdrop="static">
            <Modal.Header className="bg-dark text-light p-0 limit-height">
                <div className="container limit-width">
                    <Modal.Title><h5 className="modal-title">{tituloModal}</h5></Modal.Title>
                </div>
            </Modal.Header>

            <Modal.Body className="p-0">
                <div className="container limit-width mt-2">
                    <div className="row g-0">

                        <div className="col-12 mb-1 p-inputtext-sm pett-input">
                            <div className="p-inputgroup">
                                <span className="p-float-label pett-label w-25">
                                    <InputText className="" name="VEI_PRODUTO" value={vendasItens.VEI_PRODUTO || ''} readOnly/>
                                    <label htmlFor="VEI_PRODUTO">PRODUTO</label>
                                </span>
                                <InputText className="w-75" name="VEI_DESCRICAO" value={vendasItens.VEI_DESCRICAO || ''} readOnly/>
                                <ButtonP className="px-3 p-button-success" icon="pi pi-search" onClick={(e) => ProdutosLocOpen(e)}/>
                            </div>          
                        </div>

                        <div className="col-12 mb-1 p-inputtext-sm pett-input">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="VEI_COMPLEMENTO" value={vendasItens.VEI_COMPLEMENTO || ''} onChange={(e) => setCampo(e)} />
                                <label htmlFor="VEI_COMPLEMENTO">COMPLEMENTO</label>
                            </span>
                        </div>

                        <div className="col-12 mb-1 p-inputtext-sm pett-input">
                            <span className="p-float-label pett-label">
                                <InputNumber className="w-100 p-inputwrapper-filled" name="VEI_QUANTIDADE" value={vendasItens.VEI_QUANTIDADE || ''} onValueChange={(e) => setCampoValor(e)} mode="decimal" locale='pt-BR' ref={inputRef} onFocus={(e) => e.target.select()}/>
                                <label htmlFor="VEI_QUANTIDADE">QUANTIDADE</label>
                            </span>
                        </div>

                        <div className="col-12 mb-1 p-inputtext-sm pett-input">
                            <span className="p-float-label pett-label">
                                { process.env.REACT_APP_ALTERAR_VALOR_UNITARIO==="false"
                                    ? <InputNumber className="w-100 p-inputwrapper-filled" name="VEI_VALORUNIT" value={vendasItens.VEI_VALORUNIT || ''} onValueChange={(e) => setCampoValor(e)} mode="currency" currency="BRL" locale="pt-BR" disabled readOnly/>
                                    : <InputNumber className="w-100 p-inputwrapper-filled" name="VEI_VALORUNIT" value={vendasItens.VEI_VALORUNIT || ''} onValueChange={(e) => setCampoValor(e)} mode="currency" currency="BRL" locale="pt-BR"/>
                                }
                                <label htmlFor="VEI_VALORUNIT">VALOR UNITÁRIO</label>
                            </span>
                        </div>
                        
                        <div className="col-12 mb-1 p-inputtext-sm pett-input">
                            <span className="p-float-label pett-label">
                                <InputNumber className="w-100 p-inputwrapper-filled" name="VEI_DESCMAX" value={vendasItens.VEI_DESCMAX || ''} onValueChange={(e) => setCampoValor(e)} mode="decimal" locale="pt-BR" disabled readOnly/>
                                <label htmlFor="VEI_DESCMAX">DESCONTO MÁXIMO (%)</label>
                            </span>
                        </div>

                        {
                            process.env.REACT_APP_DESCONTO_EM_VALOR==="true"
                                ?
                                    <div className="col-12 mb-1 p-inputtext-sm pett-input">
                                        <span className="p-float-label pett-label">
                                            <InputNumber className="w-100 p-inputwrapper-filled" name="VEI_DESCVALOR" value={vendasItens.VEI_DESCVALOR || ''} onValueChange={(e) => setCampoValor(e)} mode="currency" currency="BRL" locale="pt-BR" />
                                            <label htmlFor="VEI_DESCVALOR">VALOR DESCONTO</label>
                                        </span>
                                    </div>
                                :   <div className="col-12 mb-1 p-inputtext-sm pett-input">
                                        <span className="p-float-label pett-label">
                                            <InputNumber className="w-100 p-inputwrapper-filled" name="VEI_DESCPORC" value={vendasItens.VEI_DESCPORC || ''} onValueChange={(e) => setCampoValor(e)} mode="decimal" locale="pt-BR" min={0} max={vendasItens.VEI_DESCMAX}/>
                                            <label htmlFor="VEI_DESCPORC">% DESCONTO</label>
                                        </span>
                                    </div>
                        }

                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer className='bg-dark limit-height p-0 d-flex justify-content-center'>
                <div className="container limit-width p-0">
                    <div className="btn-group w-100">
                        <Button variant="danger" onClick={() => setShowModal(false)}>Cancelar</Button>
                        <Button variant="success" onClick={() => btnSalvarItem()}><i className="fa fa-fw fa-check"></i> Salvar</Button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>

        <ProdutosLoc showModal={showProdutosLoc} onCloseModal={ProdutosLocClose}/>
        <CondicoesLoc showModal={showCondicoesLoc} onCloseModal={CondicoesLocClose}/>
    </>
}

export default Venda;