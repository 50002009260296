import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

// funcoes proprias
import { AuthContext } from '../contextos/Auth';
import { ParametrosContext } from '../contextos/Parametros';
import IfComponent from '../componentes/IfComponent';
import Api from '../Api';
import ClientesForm from '../componentes/ClientesForm';
import Sentinela from '../componentes/Sentinela';

function Clientes() {
    const { logout } = useContext(AuthContext);
    const { parametros } = useContext(ParametrosContext);
    const [ listagem, setListagem ] = useState([]);
    const [ carregando, setCarregando ] = useState(true);
    const [ pagina, setPagina ] = useState(0);
    const [ final, setFinal ] = useState(false);
    const [ pesquisa, setPesquisa ] = useState('');
    const [ showModal, setShowModal ] = useState(false);
    const [ cliente, setCliente ] = useState({
        CLI_BAIRRO: '',
        CLI_CEP: '',
        CLI_CIDADE: '',
        CLI_CNPJ: '',
        CLI_CODIGO: '',
        CLI_COMPLEMENTO: '',
        CLI_CONTATO: '',
        CLI_CPF: '',
        CLI_DTATUALIZACAO: '',
        CLI_DTNASC: '',
        CLI_EMAIL: '',
        CLI_ENDERECO: '',
        CLI_ESTADO: '',
        CLI_FONE1: '',
        CLI_FONE2: '',
        CLI_FONE3: '',
        CLI_IE: '',   
        CLI_NOME: '',
        CLI_NUMERO: '',
        CLI_RG: '',
        CLI_STATUS: 'A',
        CLI_TABELAPRECO: 'V',
        CLI_TIPOPESSOA: 'J',
        CLI_USUARIO: ''        
    });

    function listarClientes(nPagina,bFinal,bLimpar) {
        if (!bFinal) {
            setCarregando(true);    
            const novaPagina=nPagina+1;
                       
            var formData = new FormData();
            formData.set('op', 'listar');
            formData.set('pagina', novaPagina);
            formData.set('pesquisa', pesquisa);
                       
            Api.post('clientes.php',formData).then((response) => {
                if (response.data.erro==='N') {
                    const novaListagem = [];
                    if (response.data.contador > 0) {
                        if (!bLimpar){
                            //const novaListagem = [...listagem] //novalistagem recebe listagem atual.
                            novaListagem.push(...listagem); //novalistagem recebe listagem atual.
                            setListagem([]);
                        }
                        novaListagem.push(...response.data.msg); //novalistagem recebe mais a nova listagem.
                    } else {
                        //se ja tem uma listagem, continua com ela
                        //se a pesquisa retornou 30 registros, ao dar scroll para a pagina 2 que retorna com 0 registros, continua com a listagem atual.
                        if (novaPagina > 1) { 
                            novaListagem.push(...listagem); 
                        }
                    }

                    //verifica se pode conter mais registros. 
                    //se retornou com 20 registros, pode ser que ainda tenha mais.
                    //se retornou com menos de 30 registros, é pq chegou no final da lista.
                    if (response.data.contador < 30) {
                        setFinal(true);
                    }

                    setListagem(novaListagem);
                    setCarregando(false);
                    setPagina(novaPagina);
                } else {
                    response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
                }
            }).catch(({response}) => {
                setCarregando(false);
            });
        }
    }

    function Pesquisar() {
        setPagina(0);
        setFinal(false);
        setListagem([]);
        listarClientes(0,false,true);
    };
    
    const localizarKeyDown = (e) => {
        if (e.keyCode === 13) {
            Pesquisar();
        }
    }  

    //https://bestofreactjs.com/repo/marekrozmus-react-swipeable-list-react-sortable-list
    // const leadingActions = ({id}) => (
    //     <LeadingActions>
    //         <SwipeAction destructive={false} onClick={() => console.info(id, 'clique estrela')}>
    //             <div className="d-flex align-items-center bg-success text-light text-center p-3"><i className="fa fa-fw fa-star text-warning"></i> Estrela</div>
    //         </SwipeAction>     
    //     </LeadingActions>
    // );
      
    function closeClientesForm() {
        setShowModal(false);
    }
    
    function salvarClientesForm(clienteAtualizado) {
        setCliente(clienteAtualizado);
    }

    function carregarMais() {
        listarClientes(pagina,final,false);
    }

    return <>
        <div className="bg-dark fixed-top">
            <div className="container-fluid limit-width">
                <div className="row">
                    <div className="col-12 limit-height d-flex justify-content-between align-items-center">
                        <div className="link-icone"><Link to='/home'><i className="fa fa-2x fa-fw fa-chevron-left"></i></Link></div>
                        <div className="flex-grow-1"><p className="text-truncate text-center text-light fw-bolder mt-3">CLIENTES</p></div>
                        <IfComponent condicional={parametros.PAR_CLIINSERIR==="S"}>
                            <div className="link-icone"><Link to='' onClick={() => setShowModal(true)} ><i className="fa fa-2x fa-fw fa-edit"></i></Link></div>
                        </IfComponent>
                    </div>
                </div>
            </div>
            <div className="container-fluid limit-width">
                <div className="row">
                    <div className="col-12 limit-height d-flex justify-content-between align-items-center">
                        <div className="w-100">
                            <input className="form-control border-0 bg-light bg-opacity-10 rounded-pill text-light disable-form-control-focus" type="text" name="texto" value={pesquisa} placeholder="Localizar" onChange={(e) => setPesquisa(e.target.value.toUpperCase())} onKeyDown={(e) => localizarKeyDown(e)}/>
                        </div>
                        <div className="ms-2"><button className="btn btn-success rounded-pill" onClick={() => { Pesquisar(); }} >Filtrar</button></div>
                    </div>
                </div>
            </div>
        </div>
        <IfComponent condicional={ listagem.length > 0}>
            {/* <div className="container limit-width pb-2"></div> */}
            <div className="container limit-width p-0 topspace-100">
                {                     
                    listagem.map(cliente => (
                        <div className="card rounded-0 border-0 border-bottom w-100" key={cliente.CLI_CODIGO}>
                            <div className="card-body p-0">
                                <div className="col-12 pt-1 px-2 lh-2 text-truncate">
                                    <Link to={"/cliente/"+cliente.CLI_CODIGO} className="link-preto stretched-link fw-bold">{cliente.CLI_NOME}</Link>
                                </div>
                                <div className="col-12 pb-1 px-2 lh-2 text-truncate text-muted small">
                                    {cliente.CLI_FONE1} - {cliente.CLI_FONE2}
                                </div>
                            </div>
                        </div>
                    ))
                }   
            </div>
        </IfComponent>  
        <Sentinela onIntersect={carregarMais} carregando={carregando} final={final} length={listagem.length} />
        <ClientesForm show={showModal} registro={cliente} fechar={closeClientesForm} salvar={salvarClientesForm}/>
    </>
}

export default Clientes;