import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { NumericFormat } from 'react-number-format';

// funcoes proprias
import { AuthContext } from '../contextos/Auth';
import IfComponent from '../componentes/IfComponent';
import Api from '../Api';
import Sentinela from '../componentes/Sentinela';

function Metas() {
    const { logout } = useContext(AuthContext);
    const [ listagem, setListagem ] = useState([]);
    const [ carregando, setCarregando ] = useState(false);
    const [ pagina, setPagina ] = useState(0);
    const [ final, setFinal ] = useState(false);
    const [ meta, setMeta ] = useState({
        MET_CODIGO: 0,
        MET_DTFIM: '',
        MET_DTINICIO: '',
        MET_METAVALOR: '',
        MET_METAATINGIDA: 0,
        MET_STATUS: 'A',
        MET_TOTALVENDAS: 0
    });

    async function getMetaAtual() {
        const formData = new FormData();
        formData.set('op','ver');
        try {
            const response = await Api.post('metas.php',formData);
            if (response.data.erro==='N') {
                setMeta(response.data.msg);
            }
            if (response.data.erro==='S') {
                //response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
                response.data.token==='expirado'?logout():setMeta({MET_CODIGO: 0});
            }
        } catch (error) {
            console.log('CATCH',error);
        }
    }

    function listarMetas(nPagina,bFinal,bLimpar) {
        if (!bFinal) {
            setCarregando(true);    
            const novaPagina=nPagina+1;
                        
            var formData = new FormData();
            formData.set('op', 'listar');
            formData.set('pagina', novaPagina);
                       
            Api.post('metas.php',formData).then((response) => {
                if (response.data.erro==='N') {
                    const novaListagem = [];
                    if (response.data.contador > 0) {
                        if (!bLimpar){
                            //const novaListagem = [...listagem] //novalistagem recebe listagem atual.
                            novaListagem.push(...listagem); //novalistagem recebe listagem atual.
                            setListagem([]);
                        }
                        novaListagem.push(...response.data.msg); //novalistagem recebe mais a nova listagem.
                    } else {
                        //se ja tem uma listagem, continua com ela
                        //se a pesquisa retornou 20 registros, ao dar scroll para a pagina 2 que retorna com 0 registros, continua com a listagem atual.
                        if (novaPagina > 1) { 
                            novaListagem.push(...listagem); 
                        }
                    }

                    //verifica se pode conter mais registros. 
                    //se retornou com 20 registros, pode ser que ainda tenha mais.
                    //se retornou com menos de 20 registros, é pq chegou no final da lista.
                    if (response.data.contador < 20) {
                        setFinal(true);
                    }

                    setListagem(novaListagem);
                    setCarregando(false);
                    setPagina(novaPagina);
                } else {
                    response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
                }
            }).catch(({response}) => {
                setCarregando(false);
            });
        }
    }

    function carregarMais() {
        listarMetas(pagina,final,false);
    }

    useEffect(() => {    
        getMetaAtual();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    // useEffect(() => {
    //     const documentStyle = getComputedStyle(document.documentElement);
    //     const textColor = documentStyle.getPropertyValue('--text-color');
    //     const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    //     const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
    //     const data = {
    //         labels: ['JAN','FEV','MAR','ABR','MAI','JUN','JUL','AGO','SET','OUT','NOV','DEZ'],
    //         datasets: [
    //             {
    //                 type: 'bar',
    //                 label: 'Meta Valor',
    //                 backgroundColor: documentStyle.getPropertyValue('--blue-300'),
    //                 data: [10000, 15000, 15000,0,0,0,0,0,0,0,0,0]
    //             },
    //             {
    //                 type: 'bar',
    //                 label: 'Meta Atingida',
    //                 backgroundColor: documentStyle.getPropertyValue('--green-600'),
    //                 data: [13000, 11364, 23572.35,0,0,0,0,0,0,0,0,0]
    //             },
    //         ]
    //     };
    //     const options = {
    //         maintainAspectRatio: false,
    //         aspectRatio: 1.2,
    //         plugins: {
    //             legend: {
    //                 labels: {
    //                     color: textColor
    //                 }
    //             }
    //         },
    //         scales: {
    //             x: {
    //                 stacked: false,
    //                 ticks: {
    //                     color: textColorSecondary
    //                 },
    //                 grid: {
    //                     color: surfaceBorder
    //                 }
    //             },
    //             y: {
    //                 stacked: false,
    //                 ticks: {
    //                     color: textColorSecondary
    //                 },
    //                 grid: {
    //                     color: surfaceBorder
    //                 }
    //             }
    //         }
    //     };

    //     setChartData(data);
    //     setChartOptions(options);
    // }, []);

    return <>
        <div className="bg-dark fixed-top">
            <div className="container-fluid limit-width">
                <div className="row">
                    <div className="col-12 limit-height d-flex justify-content-between align-items-center">
                        <div className="link-icone"><Link to='/home'><i className="fa fa-2x fa-fw fa-chevron-left"></i></Link></div>
                        <div className="flex-grow-1"><p className="text-truncate text-center text-light fw-bolder mt-3">MINHAS METAS</p></div>
                    </div>
                </div>
            </div>
        </div>

        <div className="bg-success bg-opacity-25 container limit-width mt-5">
            <p className="lead fw-bold text-center lh-2 my-3 m-0">Meta Atual</p>
        </div>

        <IfComponent condicional={meta.MET_CODIGO===0}>
            <div className="container limit-width my-5 text-center">
                <p className="lead">Não foi possível localizar uma meta em aberto.</p>
            </div>
        </IfComponent>

        <IfComponent condicional={meta.MET_CODIGO!==0}>

            <div className="container limit-width">
                <ul className="list-group my-3">  
                    <li className="list-group-item p-2">
                        <div className="row">      
                            <div className="col-6"><p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">DATA INICIAL</sup></p><p className="lh-1 m-0">{meta.MET_DTINICIO}</p></div> 
                            <div className="col-6"><p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">DATA FINAL</sup></p><p className="lh-1 m-0">{meta.MET_DTFIM}</p></div> 
                        </div>
                    </li>
                    <li className="list-group-item p-2">
                        <div className="row">      
                            <div className="col-6">
                                <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">META VALOR</sup></p><p className="lh-1 m-0">
                                    <NumericFormat className="text-primary"
                                        value={parseFloat(meta.MET_METAVALOR)}
                                        // isNumericString={true}
                                        displayType="text"
                                        prefix="R$ "
                                        thousandsGroupStyle="thousand"
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator="."
                                        allowNegative={true}
                                    />
                                </p>
                            </div> 
                            <div className="col-6">
                                <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">TOTAL VENDAS</sup></p><p className="lh-1 m-0">
                                    <NumericFormat className="text-success"
                                        value={parseFloat(meta.MET_TOTALVENDAS)}
                                        // isNumericString={true}
                                        displayType="text"
                                        prefix="R$ "
                                        thousandsGroupStyle="thousand"
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator="."
                                        allowNegative={true}
                                    />
                                </p>
                            </div> 
                        </div>
                    </li>
                    <li className="list-group-item p-2">
                        {/* <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">META VALOR</sup></p><p className="lh-1 m-0">{meta.MET_METAVALOR}</p> */}
                        <p className="text-center fs-5 fw-bold lh-1">META ATINGIDA: {meta.MET_METAATINGIDA} %</p>
                        <div className="progress" role="progressbar" aria-label="Example with label" aria-valuenow={meta.MET_METAATINGIDA} aria-valuemin="0" aria-valuemax="100" style={{height: '30px'}}>
                            <div className="progress-bar progress-bar-striped progress-bar-animated bg-success fs-5" style={{width: meta.MET_METAATINGIDA+'%', height: '30px'}}>{meta.MET_METAATINGIDA} %</div>
                        </div>
                    </li>
                </ul>           
            </div>

        </IfComponent>

        <div className="bg-info bg-opacity-25 container limit-width">
            <p className="lead fw-bold text-center lh-2">Metas Anteriores</p>
        </div>

        <IfComponent condicional={ listagem.length > 0}>
            <div className="container limit-width pb-5 p-0">
                {/* <div className="card mb-3 mx-2">
                    <Chart type="bar" data={chartData} options={chartOptions} />
                </div> */}
                {                     
                    listagem.map(item => (
                        <div className="card rounded-0 border-0 border-bottom w-100" key={item.MET_CODIGO}>
                            <div className="card-body p-0">
                                <div className="px-2 pt-1 d-flex justify-content-between">
                                    {/* {item.MET_DTINICIO} à {item.MET_DTFIM} */}
                                    <span>{item.MET_DTINICIO}</span>
                                    <span>{item.MET_DTFIM}</span>
                                    {/* <span className="badge bg-success rounded-pill">FECHADA</span> */}
                                    {/* <p><span className="badge bg-success rounded-pill">{item.MET_STATUS}</span></p> */}
                                </div>
                                <div className="px-2 lh-2 d-flex justify-content-between">
                                    <NumericFormat className="text-primary"
                                        value={parseFloat(item.MET_METAVALOR)}
                                        // isNumericString={true}
                                        displayType="text"
                                        prefix="R$ "
                                        thousandsGroupStyle="thousand"
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator="."
                                        allowNegative={true}
                                    />
                                    <span className="text-danger">{item.MET_METAATINGIDA} %</span>
                                    <NumericFormat className="text-success"
                                        value={parseFloat(item.MET_TOTALVENDAS)}
                                        // isNumericString={true}
                                        displayType="text"
                                        prefix="R$ "
                                        thousandsGroupStyle="thousand"
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator="."
                                        allowNegative={true}
                                    />
                                </div>
                                <div className="col-12 pb-2 px-2 lh-2 text-truncate text-muted small">
                                    <div className="progress" role="progressbar" aria-label="Example with label" aria-valuenow={item.MET_METAATINGIDA} aria-valuemin="0" aria-valuemax="100">
                                        <div className="progress-bar progress-bar-striped bg-primary" style={{width: item.MET_METAATINGIDA+'%'}}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                } 
            </div>
        </IfComponent>           
        <Sentinela onIntersect={carregarMais} carregando={carregando} final={final} length={listagem.length} />

    </>
}

export default Metas;